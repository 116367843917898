import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ListSubheader from '@material-ui/core/ListSubheader';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Switch, Route, Link, BrowserRouter } from "react-router-dom";

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  listIndentation: {
    paddingLeft: 0
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerSectionListItemText: {
    color: '##292929',
    fontSize:'1.2em',
    fontWeight: "bold"
  },
  drawerListItemText: {
    color: '#797979'
  },
  appBar: {
    background : '#5926d6',
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    background: '#fbfbfb'
  },
}));

const WhiteTextTypography = withStyles({
  root: {
    color: "#FFFFFF"
  }
})(Typography);



// SECTION ResponsiveDrawer

function ResponsiveDrawer(props) {
  
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const initialDepthLevelSpacing = 2

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List 
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {props.title}
          </ListSubheader>
        }
        disablePadding
        className={classes.listIndentation}>
        {props.menuItems.data.map((menuItem, index) => {
          if(menuItem.children) {
            return DrawerSectionItem(menuItem, initialDepthLevelSpacing)
          } else {
            return DrawerItem(menuItem, index)
          }
        })}
      </List>
    </div>
  );

  
  
  

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <WhiteTextTypography variant="h5" 
            noWrap 
            to="/"
            component={Link}>
            Chrishon Wyllie
          </WhiteTextTypography>
        </Toolbar>
      </AppBar>
      <BrowserRouter>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          <div className={classes.toolbar} />

          {recursivelyRenderRoutes(props.menuItems.data)}
        </main>
      </BrowserRouter>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default ResponsiveDrawer;






// SECTION DrawerSectionItem

function DrawerSectionItem (menuItem, depthLevelSpacing) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme()

  const handleClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const determineDropdownState = () => {
    // Attempt to open all dropwdowns until we reach the 
    // path matching the current pathname
    if(window.location.pathname.startsWith(menuItem.matchPath)) {
      return (dropdownOpen !== true)
    } else {
      return dropdownOpen
    }
  }

  // Move the section over by (theme.spacing(VALUE)) for each
  // depth level. In this case, 2
  const sequentialDepthLevelAddition = 2
  const newDepthLevelSpacing = depthLevelSpacing + sequentialDepthLevelAddition

  return (

    <div key={ menuItem.title }>
      <ListItem
          style={{ paddingLeft: theme.spacing(depthLevelSpacing) }}
          button 
          onClick={ () => handleClick() }>
              <ListItemText 
                  classes = {{ primary: classes.drawerSectionListItemText }}
                  primary={ menuItem.title } />
                  { determineDropdownState() ? 
                      <ExpandLess /> :
                      <ExpandMore />
                  }
      </ListItem>
      <Divider />
      <Collapse 
          in={ determineDropdownState() } 
          timeout="auto" 
          unmountOnExit>
          { renderDrawerSectionChildren(menuItem.children, newDepthLevelSpacing) }
      </Collapse>
    </div>
  )
}

// SECTION DrawerItem

function DrawerItem (menuItem, depthLevelSpacing) {
  const classes = useStyles();
  const theme = useTheme()
  
  return (
    <ListItem 
      style={{ paddingLeft: theme.spacing(depthLevelSpacing) }}
      key={menuItem.url} 
      component={Link} 
      to={menuItem.url}>
      <ListItemText 
        classes={{ primary: classes.drawerListItemText }}
        primary={menuItem.title} />
    </ListItem>
  )
}

function renderDrawerSectionChildren( children, depthLevelSpacing) {
  return children.map( (menuItem) => {
      if(menuItem.children) {
        return DrawerSectionItem(menuItem, depthLevelSpacing)
      } else {
        return DrawerItem(menuItem, depthLevelSpacing)
      }
  })
}

function recursivelyRenderRoutes(menuItems) {
  return (
    <Switch>
      {menuItems.map((menuItem) => {
          if(menuItem.children) {
            return renderNestedRoutes(menuItem.children)
          } else {
            return renderRoute(menuItem)
          }
      })}
    </Switch>
  )
}

function renderNestedRoutes(menuItems) {
  const routes = menuItems.map((menuItem) => {
    if(menuItem.children) {
      return renderNestedRoutes(menuItem.children)
    } else {
      return renderRoute(menuItem)
    }
  });
  return routes
}

function renderRoute(menuItem) {
  return (
    <Route 
      key={menuItem.url}
      exact path={menuItem.url}
      component={menuItem.component}
    />
  )
}