import React from 'react';

import { NavBar } from './navbar';
import HeaderArea from './header';
import AboutArea from './about';
import { PortfolioProjects } from './portfolio';
import ResumeArea from './resume';
import WorkArea from './freelancework';
import BlogArea from './blog';
// import ContactArea from './contact';

export default class Home extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            navbarHeight: 0,
            windowWidth: 0, 
            windowHeight: 0
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    
    componentDidMount() {
        // const navbarHeight = this.navbarElement.clientHeight;

        // this.animated_elements = document.getElementsByClassName('animated_element');
        
        
        // this.setState({ navbarHeight : this.navbarElement.clientHeight });
        

        this.updateWindowDimensions();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.updateWindowDimensions);
    }
	
	componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
      
    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
    }
    
    handleScroll(event) {
        
        // MARK: - animated skill bar in ResumeArea
        
        // For any animated element. Adds the 'in-view' class
    
        var animated_elements = document.getElementsByClassName('animated-element');

        Array.prototype.forEach.call(animated_elements, function(animated_element) {
            var element_top_position = animated_element.getBoundingClientRect().top;
            let offset = animated_element.clientHeight;

            let isInViewport = (element_top_position + offset) >= 0 && (element_top_position - offset) <= window.innerHeight;
    
            if (isInViewport === true) {

                animated_element.classList.add('in-view');
            } else {
                // This would continually cause the animation to happen when the user scrolls up and down
                //$element.removeClass('in-view');
            }
        });
    }
    
    render() {
        return (
            <div>
                <NavBar
                    links= {[
                        {linkTo: "HomeLink", text: "Home"},
                        {linkTo: "AboutLink", text: "About"},
                        {linkTo: "PortfolioWorkLink", text: "Portfolio"},
                        {linkTo: "BlogLink", text: "Blog"},
                        {linkTo: "ResumeLink", text: "Resume"}, 
                        {linkTo: "FreelanceWorkLink", text: "Work"},
                        {linkTo: "/docs/home", text: "Docs", isURLComponentNavLink: true },
                        {linkTo: "mailto:chrishonwyllie@gmail.com", text: "Contact", isExternalLink: true}
                    ]} 
                />

                <HeaderArea />
                <AboutArea />
                <PortfolioProjects />
                <BlogArea />
                <ResumeArea />
                <WorkArea />
                {/* Temporarily deprecated the ContactArea until a fix can be 
                found for using your own form to send emails.
                <ContactArea /> */}
            </div>
        );
    }
}