import React from 'react';
import '../css/index.css';

export default class ResumeArea extends React.Component {
	render() {

		return (
			<div>
				{/* This span is used to avoid the issue of the navbar covering the elements in this div */}
				<span className="anchor-link" id="ResumeLink"></span>				
				<div className="work-background-parallax global-padding-top-bottom-20px">
					
					
					<div className="container">
						<h3 className="text-center section-title-header-white">Resume</h3>
					</div>
						
						
					<div className="resume-items-container">
						
						<div className="row global-padding-40px">
						
							{/* Socket Mobile */}
						
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 resume-item-outer-container animated-element-container">
								
								<div className="resume-item-inner-container animated-element slide-up">
									
									<div className="">
									
										<div className="">
											<h3 className="resume-item-company text-center">Socket Mobile</h3>
											<p className="resume-item-role text-center">SDK Software Engineer</p>
										</div>
									
									</div>
								
									<div className="">
									
										<div className="">
											<h3 className="resume-item-employment-timeline text-center">Jan 2018 - Present</h3>
										</div>
										
										<div className="row resume-technologies-row">
											<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4			offset-md-2 offset-lg-2">
												<i className="devicon-swift-plain devicon-color fa-4x horizontal-center" />
											</div>
											
											<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
												<i className="devicon-cplusplus-plain devicon-color fa-4x horizontal-center" />
											</div>
										</div>
									</div>
								</div>
							</div>
							
							
						
							{/* Next Gear Crew */}
						
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 resume-item-outer-container animated-element-container">
								
								<div className="resume-item-inner-container animated-element slide-up">
									
									<div className="">
									
										<div className="resume-next-gear-crew">
											<h3 className="resume-item-company text-center"> Next Gear Crew</h3>
											<p className="resume-item-role text-center">iOS and Android Engineer</p>
										</div>
									
									</div>
								
									<div className="">
									
										<div className="resume-next-gear-crew">
											<h3 className="resume-item-employment-timeline text-center">July 2015 - Jan 2018</h3>
										</div>
										
										<div className="row animated-element-slide-up resume-technologies-row">
											
											<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 offset-md-2 offset-lg-2">
												<i className="devicon-swift-plain devicon-color fa-4x horizontal-center" />
											</div>
											
											<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
												<i className="devicon-java-plain-wordmark devicon-color fa-4x horizontal-center" />
											</div>
											
											<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
												<i className="devicon-firebase-plain devicon-color fa-4x horizontal-center" />
											</div>
											
										</div>
									</div>
								</div>
							</div>
						
						
						
						
							{/* Dal Electric */}
						
						
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 resume-item-outer-container animated-element-container">
								
								<div className="resume-item-inner-container animated-element slide-up">
									
									<div className="">
									
										<div className="">
											<h3 className="resume-item-company text-center">Dal Electric Corporation</h3>
											<p className="resume-item-role text-center">Freelance Front-End Web Developer</p>
										</div>
									
									</div>
								
									<div className="">
										<h3 className="resume-item-employment-timeline text-center">August 2017 - Present</h3>
										
										<div className="row resume-technologies-row"> 
											<div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
												<i className="devicon-html5-plain-wordmark devicon-color fa-4x horizontal-center" />
											</div>
										
											<div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
												<i className="devicon-css3-plain-wordmark devicon-color fa-4x horizontal-center" />
											</div>
										
											<div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
												<i className="devicon-javascript-plain devicon-color fa-4x horizontal-center" />
											</div>
										
											<div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
												<i className="devicon-react-plain-wordmark devicon-color fa-4x horizontal-center" />
											</div>
										</div>
									</div>
								</div>
							</div>
						
						
						
						
							{/* RedPlanet */}
						
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 resume-item-outer-container animated-element-container">
								
								<div className="resume-item-inner-container animated-element slide-up">
									
									<div className="">
									
										<div className="resume-next-gear-crew">
											<h3 className="resume-item-company text-center">Redplanet</h3>
											<p className="resume-item-role text-center">iOS Engineer</p>
										</div>
									
									</div>
								
									<div className="">
									
										<div className="resume-next-gear-crew">
											<h3 className="resume-item-employment-timeline text-center">May 2017 - August 2017</h3>
										</div>
										
										<div className="row resume-technologies-row">
											<div className="col-xs-4 col-sm-4 col-md-4 col-lg-4    offset-xs-4 offset-sm-4 offset-md-4 offset-lg-4">
												<i className="devicon-swift-plain fa-4x devicon-color horizontal-center" />
											</div>
										</div>
									</div>
								</div>
							</div>
							
						</div>
					</div>
						
						
					{/* See Resume (Show .pdf file) */}
					
					<div className="container animated-element-container">
						<div className="row animated-element slide-up global-margin-top-60px">
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6    offset-md-3 offset-lg-3 global-padding-30px">
								
								<a className="horizontal-center" href="https://drive.google.com/open?id=1ekcXPlFZzWmWuMD82o_v0Lgausrn-QfE">
									<input type="button" value="See my resume" className="bttn bttn-rounded bttn-bordered box-shadow" />
								</a>
							
							</div>
						</div>
					</div>
					
				</div>
			</div>
		);
	}
}