import React from 'react';

import '../../css/navbar.css';
import '../../css/index.css';
import dedent from "dedent";

import ResponsiveDrawer from './responsivedrawer'
import { DocsDrawerItems, DocumentationSectionType } from './docslayoutdescriptions'

import Markdown from "./markdown";
import { NavBar } from '../navbar'
import AnimatableCard from '../animatablecard';
import ReactPlayer from 'react-player';

export function DocsCentralHubComponent ({ match }) {

    return (
        <div>
            <ResponsiveDrawer title="Docs" menuItems={DocsDrawerItems} />
        </div>
    )
}

// Middle-ground view for displaying docs that can be viewed,
// prior to showing Sidebar
export function DocsHomeComponent ({ match }) {

    const ImageCompletePath = (slug) => {
        return `${process.env.PUBLIC_URL}/assets/images/portfolio/${slug}`
    }

    const docsItems = [
        {
            url: '/docs/celestial/',
            title: "Celestial",
            imageURL:  ImageCompletePath("celestial/Celestial-icon.png")
            
        }, 
        {
            url: '/docs/composabledatasource/',
            title: "ComposableDataSource"
        }
    ].map((link) => {
        return (
            <AnimatableCard 
                key={link.url} 
                linkTo={link.url} 
                imageURL={link.imageURL} 
                alternativeImageTitle={link.title} 
                title={link.title} />
        )
    })

    

    return (
        <div>
            <NavBar />
            <section style={{ background: '#f0f0f0' }}>
                <h2>Docs</h2>

                <p>Take a look through the documentation of some of my open source projects</p>

                <div className="container">
                    <div className="row">
                        {docsItems}
                    </div>
                </div>
            </section>
        </div>
        
    )
}

export function ProjectDocComponent ({ match }) {

    const docItem = recursivelyLookthroughChildren(DocsDrawerItems.data, match)

    return (
        <div style={{textAlign: "left"}}>
            <h2>{docItem.title}</h2>
            <p>{docItem.description}</p>

            <Version docItem={docItem} />
            <hr />

            {DocumentationSection(docItem.documentation)}

        </div>
    )
}

function recursivelyLookthroughChildren(parentItems, match) {

    // If navigating from an external location and given an exact match,
    // there is a possibility that the url matches exactly
    // Return this match immediately
    const immediatelyFoundRequestedChildItem = parentItems.find(({ url }) => match.path === url || match.url === url )
    if(immediatelyFoundRequestedChildItem !== undefined) {
        return immediatelyFoundRequestedChildItem
    }
    
    // ... Otherwise, look through all data until a matching key-value pair is found
    const parentItem = parentItems.find(({ matchPath }) => match.url.startsWith(matchPath))

    var childItem = {};

    // return immediately if reached end of tree
    if ((parentItem.children === undefined) || (parentItem.children.length === 0)) {
        return null
    }

    childItem = parentItem.children.find(({ url }) => url === match.url)
    
    // If this path does not contain the exact match
    // look through the other children in
    if (childItem === undefined) {
        return recursivelyLookthroughChildren(parentItem.children, match)
    } else {
        return childItem
    } 
}

class Version extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            latestBuild: ''
        }

        this.optionallyRenderVersion = this.optionallyRenderVersion.bind(this)
    }
   
    componentDidMount() {
        
        this.fetchLatestVersion()
    }

    fetchLatestVersion = () => {
        if(this.props.docItem.repoPath === undefined) {
            return
        }
        const docItem = this.props.docItem

        const repoTagURL = 'https://api.github.com/repos/' + docItem.repoPath + '/tags';
        const semverCompare = require('semver/functions/compare')

        fetch(repoTagURL)
            .then(response => response.json())
            .then(data => {
                var sortedVersions = data.sort(function (v1, v2) {
                    return semverCompare(v2.name, v1.name)
                });
                const latestBuild = sortedVersions[0].name
                this.setState({ latestBuild: latestBuild })
            })
            .catch(error => console.error(error))
    }

    optionallyRenderVersion = () => {
        if(this.props.docItem.repoPath === undefined) {
            return null
        }
        return (
            <div>
                <h3>Latest Version</h3>
                <a href={this.props.docItem.repoURL}>
                    <p style={{
                        margin: '20px 20px 20px 20px',
                        padding: '10px 15px 10px 15px',
                        color: 'white',
                        borderRadius: 7,
                        background: '#5926d6',
                        fontSize: '1.3em',
                        display: 'inline-block',
                        fontWeight: 'bold',
                        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                    }}>{this.state.latestBuild}
                    </p>
                </a>
            </div>
        )
     }

    render() {
        return this.optionallyRenderVersion()
    }
}

function DocumentationSection (documentation) {
    if (!documentation) {
        return null
    }

    if (!documentation.length) {
        return null
    }

    return documentation.map((renderDetails) => {
        return (
            <div style={{padding: '15px 15px 15px 15px'}}>
                {DocumentationRenderDetauls(renderDetails)}
            </div>
        )
    })
}

function DocumentationRenderDetauls(renderDetails) {
    switch (renderDetails.type) {
        case DocumentationSectionType.text:
            return (
                <div>
                    <p style={{fontSize: '1.2em'}}>{renderDetails.text}</p>
                </div>
            )
        case DocumentationSectionType.image:
            const images = renderDetails.images.map((imageURL) => {
                return (
                    <div className="col-md-4">
                        <img src={imageURL} alt={imageURL} />
                    </div>
                )
            })

            
            return (
                <div className="container">
                    <div className="row">
                        {images}
                    </div>
                </div>
            )
        case DocumentationSectionType.video:
            const videos = renderDetails.videos.map((videoURL) => {
                console.log(videoURL)
                return (
                    <ReactPlayer
                        url={videoURL}
                        loop={true}
                        controls={false}
                        volume={0}
                        playing={true} />
                )
            })
            return (
                <div className="container">
                        <div className="row">
                            {videos}
                        </div>
                    </div>
            )
        case DocumentationSectionType.markdown:

            var markdown;

            if(renderDetails.language) {
                // First dedent the text from the const
                const formattedCodeblock = dedent`
                \`\`\`${renderDetails.language}
                ${renderDetails.text}
                \`\`\``

                // Then dedent the formatted code block
                // This second dedent is required for some reason.
                // Otherwise, there is a weird indentation
                 markdown = dedent(formattedCodeblock)
            } else {
                // Typical markdown
                
                markdown = dedent(renderDetails.text)
            }
            

            return (
                <div>
                    <Markdown source={markdown} />
                </div>
            )
    
        default:
            return null
    }
}