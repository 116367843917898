import React from 'react';
import uuid from 'react-uuid';
import '../css/index.css';
import AnimatableCard from './animatablecard';

export default class BlogArea extends React.Component {
    render() {

        var blogArticles = [];

        if (this.props.blogArticles !== undefined) {
            blogArticles = this.props.blogArticles.map(function(article) {
                return (
                    <AnimatableCard key={uuid()} linkTo={article.linkTo} imageURL={article.imageURL} alternativeImageTitle={article.alternativeImageTitle} title={article.title} />
                );
            });
        } else {
            // This component was declared without the portfolioProjects identifier,
            // just show them all
            blogArticles = Object.values(BlogArticleInfo).map(function(article) {
                return (
                    <AnimatableCard key={uuid()} linkTo={article.linkTo} imageURL={article.imageURL} alternativeImageTitle={article.alternativeImageTitle} title={article.title} />
                );
            });
        }

        return (
            <div>
                <span className="anchor-link" id="BlogLink"></span>
				<section className="other-projects-section">
					<div className="container">
						
						<h2 className="text-center">Articles</h2>

                        <div className="row portfolio-row">
                            {blogArticles}
                        </div>
                    </div>
                </section>
            </div>
            
        );
    }
}

const BlogArticleInfo = {
    "sharing_realm_data": {
        linkTo:"https://medium.com/@chrishonwyllie/sharing-realm-data-between-devices-in-ios-13-7eb2e53bcf53",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/blog/sharing-realm-data.png`,
        alternativeImageTitle:"Sharing Realm Data",
        title:"Sharing Realm Data"
    }
};