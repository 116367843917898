import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import CodeBlock from './codeblock'

class Markdown extends React.PureComponent {
    render() {
        return (
            <ReactMarkdown
                source={this.props.source}
                renderers={{
                    code: CodeBlock,
                }}
            />
        );
    }
}

Markdown.propTypes = {
    value: PropTypes.string.isRequired,
};

export default Markdown