

import React from 'react';
import { PortfolioStaticDetails } from './portfoliodetaildescriptions';
import '../../css/portfolio.css';
import { NavBar } from '../navbar';
import { PortfolioProjectInfo, PortfolioProjects } from '../portfolio';
import SkillBar from 'react-skillbars';
import Modal from 'react-modal';
import { IntlProvider, FormattedHTMLMessage } from 'react-intl';


// This component is a reusable ui component that will represent the skeleton
// of each portfolio. When clicking on a project image. The data relevant to that
// project will be used to populate this

export class PortfolioDetailComponent extends React.Component {
    constructor(props) {
        super(props);
        
        let identifier = `${props.portfolioProjectIdentifier}`;

        // Get all the other projects excluding this one by filtering 
        // for every project whose key is NOT this one
        var otherProjects = Object.keys(PortfolioStaticDetails).filter((projectName) => {
            return (projectName !== identifier);
        }).map((projectidentifier, index) => {
            return PortfolioProjectInfo[projectidentifier];
        });
        
        this.state ={ details: PortfolioStaticDetails[identifier], otherPortfolioProjects: otherProjects }


        // Necessary for react modal
        // See here: http://reactcommunity.org/react-modal/accessibility/#app-element
        Modal.setAppElement('#root');
    }

    // This component may or may not have a 'progression'
    // key-value pair defined.
    // If it does, a ProgressionSection tag will be rendered,
    // otherwise, nothing will be shown.
    optionallyRenderProgressionSection() {
        if (this.state.details.progression !== undefined) {
            return (
                <ProgressionSection progressionLevel={this.state.details.progression} />
            );
        }
    }

    optionallyRenderMilestoneSection() {
        if (this.state.details.milestones !== undefined) {
            return (
                <MilestoneSection milestones={this.state.details.milestones} />
            );
        }
    }

    optionallyRenderDescriptionsSection() {
        if(this.state.details.descriptions !== undefined) {
            const descriptions = [
                <PortfolioDescriptionColumn description_text={this.state.details.descriptions.description_text_one} />,
                    <PortfolioDescriptionColumn description_text={this.state.details.descriptions.description_text_two} />,
                    <PortfolioDescriptionColumn description_text={this.state.details.descriptions.description_text_three} />
            ]
            return descriptions
        }
        
    }
    
    render() {
        return (
            <div>
				<NavBar />
				<div className="portfolio-header-section">
					<h2 className="portfolio-header-title text-center">{this.state.details.title}</h2>
				</div>
			
				<section className="images-section">
                    <DemoSection images={this.state.details.images} videos={this.state.details.videos} />
					
                    <OpenSourceSection buttons={this.state.details.buttons} />
					
				</section>

                {/* See above */}
                {this.optionallyRenderProgressionSection()}
                {this.optionallyRenderMilestoneSection()}
				
				<section className="information-section">
					<div className="container">
						
						<div className="row global-padding-topbottom-20px">
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
								<h3 className="text-center portfolio-language-used-header">Language Used:</h3>
                                <UsedLanguagesList languages_used={this.state.details.languages_used} />
							</div>
							
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
								<h3 className="text-center portfolio-technologies-used-header">Technologies used:</h3>
								<UsedTechnologiesList used_technologies={this.state.details.used_technologies} />
							</div>
						</div>
					
						
						<div className="row global-padding-topbottom-20px">
							{this.optionallyRenderDescriptionsSection()}
						</div>
						
					</div>
				</section>

                <section>
                    <PortfolioProjects portfolioProjects = {this.state.otherPortfolioProjects} />
                </section>
			</div>
        );
    }
}

// Shows four images/gifs in horizontal columns
class DemoSection extends React.Component {

    // The parent component 'PortfolioDetailComponent' may or may not
    // be declared with a 'videos' key-value pair.
    // If it is defined, videos will be displayed with this component.
    // Otherwise, nothing is displayed.
    optionallyRenderVideosSection() {

        if (this.props.videos !== undefined) {

            var videos = this.props.videos.map((videoURL, index) => {
                return (
                    <DemoVideo id={index} url={videoURL} totalCount={this.props.videos.length} />
                );
            });

            return(
                <div className="row">
                    {videos}
                </div>
            );
        } 
    }

    render() {

        var images = this.props.images.map((imageURL, index) => {
            return (
                <DemoImage id={index} url={imageURL} totalCount={this.props.images.length} />
            );
        });

        // TODO
        // Replace this with a similar optionallyRender... function
        if (images.length > 0) {
            return (
                <div>
                    <div className="container">
                        <div className="row">
                            {images}
                        </div>
                    </div>
                    <div className="container global-padding-top-bottom-40px">
                        {this.optionallyRenderVideosSection()}
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 offset-mf-4 offset-lg-4">
                            <p className="text-center">Images and videos coming soon!</p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

class DemoImage extends React.Component {
    constructor(props) {
        super(props);

        if (props.totalCount === 4) {
            this.state = {desktopColumnWidth: 3, centeredColumnOffset: "centered-offset-not-required-here"}
        } else if (props.totalCount === 3) {
            this.state = {desktopColumnWidth: 4, centeredColumnOffset: "centered-offset-not-required-here"}
        } else if (props.totalCount === 2) {
            this.state = {desktopColumnWidth: 6, centeredColumnOffset: "centered-offset-not-required-here"}
        } else if (props.totalCount === 1) {
            this.state = {desktopColumnWidth: 4, centeredColumnOffset: 4}
        }
    }
    render() {
        return (
            <div className={"portfolio-image-video-container col-xs-6 col-sm-6 col-md-" + this.state.desktopColumnWidth + " col-lg-" + this.state.desktopColumnWidth + " offset-md-" + this.state.centeredColumnOffset + " offset-lg-" + this.state.centeredColumnOffset}>
                <img className="portfolio-image-mobile img-responsive" alt={"demo_image_" + this.props.id} src={this.props.url} />
            </div>
        );
    }
}

class DemoVideo extends React.Component {
    constructor(props) {
        super(props);
        
        if (props.totalCount === 4) {
            this.state = {desktopColumnWidth: 3}
        } else if (props.totalCount === 3) {
            this.state = {desktopColumnWidth: 4}
        } else if (props.totalCount === 2) {
            this.state = {desktopColumnWidth: 6}
        } else if (props.totalCount === 1) {
            this.state = {desktopColumnWidth: 6, offsetClassNames: "offset-md-3 offset-lg-3"}
        }
    }
    render() {
        return (
            <div className={"col-xs-6 col-sm-6 col-md-" + this.state.desktopColumnWidth + " col-lg-" + this.state.desktopColumnWidth + this.state.offsetClassNames || ""}>
                <div className="portfolio-video-wrapper video-responsive">
                    <iframe title={this.props.url} className="" alt={"demo_video_" + this.props.id} src={this.props.url}></iframe>
                </div>
            </div>
        );
    }
}

// Shows one or more buttons to download the ios/android app or to view on github
class OpenSourceSection extends React.Component {
    optionallyRender() {
        if(this.props.buttons) {
            var buttons = this.props.buttons.map(function(button) {
                return (
                    <DownloadButton projectType={button.projectType} url={button.url} />
                );
            });
            
            // Center either a single button or two
    
            if (buttons.length === 2) {
                return (
                    <div className="container global-padding-topbottom-60px">
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 offset-md-2 offset-lg-2">
                                {buttons[0]}
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                                {buttons[1]}
                            </div>
                        </div>
                    </div>
                );
            } else if (buttons.length === 1) {
    
                return (
                    <div className="container global-padding-topbottom-60px">
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 offset-md-4 offset-lg-4">
                                {buttons}
                            </div>
                        </div>
                    </div>
                );
            } else {
                // render nothing. This project is not open source or downloadable
                return null;
            }
        }
    }
    render() {

        return (
            <div>
                {this.optionallyRender()}
            </div>
        )
    }
}










// SECTION DownloadButton

class DownloadButton extends React.Component {
    constructor(props) {
        super(props);

        switch(this.props.projectType) {
            case "ios":
                this.state = { buttonClassName: "apple-app-store-bttn", iconClassName: "fa fa-apple fa-2x horizontal-center" };
                break;
            case "web":
                // NOTE
                // TODO: Find an favicon for web instead of using a different button
                this.state = { buttonClassName: "", iconClassName: "bttn bttn-rounded bttn-bordered box-shadow"}    
                break;
            case "open_source":
                // NOTE
                // TODO: Add possibility of web, javascript or android open source projects
                // This assumes all open source projects are ios projects
                this.state = { buttonClassName: "apple-app-store-bttn", iconClassName: "fa fa-github fa-2x horizontal-center" };
                break;
            case "private":

                break;
            case "android":
                this.state = { buttonClassName: "google-play-store-bttn", iconClassName: "fa fa-android fa-2x horizontal-center" };
                break;
            default: break;
        }
    }
    render() {

        if (this.props.projectType === "web") {
            return (
                <div className="form-group global-padding-20px center">
                    <a className={this.state.buttonClassName} href={this.props.url}>
                        <input type="button" value="Visit Website" className="bttn bttn-rounded bttn-bordered box-shadow" />
                    </a>
                </div>
            );
        } else if (this.props.projectType === "private") {
            return null;
            // return (
            //     <div className="form-group global-padding-20px center">
            //         <h2 className="text-center">
            //             This project is not complete and thus not open source (yet!)
            //         </h2>
            //     </div>
            // );
        } else {
            
            return (
                <div className="form-group global-padding-20px center">
                    <a className={this.state.buttonClassName} href={this.props.url}>
                        <i className={this.state.iconClassName} />
                    </a>
                </div>
            );
        }
    }
}











// SECTION Progress

class ProgressionSection extends React.Component {

    formatProgressionText() {
        var text = `${this.props.progressionLevel[0].text}`;
        text = text.split("\n").map((i,key) => {
            return <div key={key}>{i}</div>;
        })
        return (
            text
        );
    }
    
    render() {

        const skillbar_colors = {
            "bar": "#5318e4",
            "title": {
              "text": "#fff",
              "background": "#5926d6"
            }
        }

        return (
            <div className="portfolio-progression-section">
                <div className="container global-padding-top-bottom-40px">
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8     offset-md-2 offset-lg-2">
                            <SkillBar 
                                skills={this.props.progressionLevel} 
                                colors={skillbar_colors} 
                            />
                            <p className="portfolio-progression-text">
                                {/* {this.formatProgressionText()} */}
                                {this.props.progressionLevel[0].text}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class MilestoneSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = { milestoneReferences : {} };

        this.milestoneReferences = [];
        
        this.handleOpenModalMilestoneView = this.handleOpenModalMilestoneView.bind(this);
    }

    handleOpenModalMilestoneView(milestoneId) {
        let milestoneRef = this.milestoneReferences[milestoneId];
        milestoneRef.handleOpenModal();
    }

    render() {
        var milestones = this.props.milestones.map((milestone, index) => {
            return (
                <Milestone 
                    milestoneId={index} 
                    milestoneInfo={milestone} 
                    totalCount={this.props.milestones.length}
                    handleOpenModalMilestoneView={this.handleOpenModalMilestoneView}
                    />
            );
        });


        // TODO
        // This can be cleaner than just a copy and paste
        var modalsForMilestones = this.props.milestones.map((milestone, index) => {
            return (
                <MilestoneModalView 
                    ref={(input) => {this.milestoneReferences[index] = input }}  
                    milestoneInfo={milestone} 
                    totalCount={(milestone.demoVideos) ? milestone.demoVideos.length : 2 }
                />
            );
        });

        return (
            <div className="portfolio-milestone-section">
                <div class="container">
                    <div className="row">
                        {milestones}
                    </div>
                </div>
                {modalsForMilestones}
            </div>
        );
    }
}









// SECTION Milestone

class Milestone extends React.Component {
    constructor(props) {
        super(props);

        // Apply offset for only the first div
        if (props.milestoneId === 0) {
            switch (props.totalCount) {
                case 2: 
                    this.state = { offsetClassNames: "offset-md-4 offset-lg-4"}
                    break;
                case 3: 
                    this.state = { offsetClassNames: "offset-md-3 offset-lg-3"}
                    break;
                case 4: 
                    this.state = { offsetClassNames: "offset-md-2 offset-lg-2"}
                    break;
                case 5:     
                    this.state = { offsetClassNames: "offset-md-1 offset-lg-1"}
                    break;
                default: 
                    break;
            }
        } else {
            this.state = { offsetClassNames: ""}
        }
    }
    render() {
        return (
            <div className={"col-xs-6 col-sm-6 col-md-2 col-lg-2 " + this.state.offsetClassNames} 
            onClick = { () =>
                this.props.handleOpenModalMilestoneView(this.props.milestoneId)
            }>
                <div className="portfolio-milestone-container">
                    <p className="portfolio-milestone-text">{this.props.milestoneInfo.title}</p>
                    <div className="portfolio-milestone-thumbnail-image-overlay">
                        <button className="portfolio-image-button bttn-inverted bttn-rounded bttn-inverted-bordered">View</button>
                    </div>
                </div>
                

            </div>
        );
    }
}


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
    //   backgroundColor       : 'red'
    }
};

class MilestoneModalView extends React.Component {
    constructor(props) {
        super(props);

        console.log("milestone modal view props: ", props);

        if (props.totalCount === 4) {
            this.state = {modalIsOpen: false, desktopColumnWidth: 3, centeredColumnOffset: "centered-offset-not-required-here"}
        } else if (props.totalCount === 3) {
            this.state = {modalIsOpen: false, desktopColumnWidth: 4, centeredColumnOffset: "centered-offset-not-required-here"}
        } else if (props.totalCount === 2) {
            this.state = {modalIsOpen: false, desktopColumnWidth: 6, centeredColumnOffset: "centered-offset-not-required-here"}
        } else if (props.totalCount === 1) {
            this.state = {modalIsOpen: false, desktopColumnWidth: 12, centeredColumnOffset: "centered-offset-not-required-here"}
        } else {
            this.state = {modalIsOpen: false}
        }

        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal () {
        this.setState({ modalIsOpen: true });
    }
    
    handleCloseModal () {
        this.setState({ modalIsOpen: false });
    }

    optionallyRenderMilestoneDemoVideos() {
        console.log("demo video urls: ", this.props.milestoneInfo.demoVideos);
        if (this.props.milestoneInfo.demoVideos !== undefined) {

            var milestoneVideos = this.props.milestoneInfo.demoVideos.map((demoVideo, index) => {
                return (
                    <ModalMilestoneVideo 
                        ref={(input) => {this.props.milestoneInfo.demoVideos[index].videoURL = input }}  
                        demoVideo={demoVideo}
                        desktopColumnWidth={this.state.desktopColumnWidth}
                        centeredColumnOffset={this.state.centeredColumnOffset}
                    />
                );
            });
            
            console.log("modal milestone videos: ", milestoneVideos);
            
            if (milestoneVideos.length > 0) {
                return (
                    <div className="container">
                        <div className="row">
                            {milestoneVideos}
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="purposely-empty-milestone-videos-div"></div>
                )
            }
        }
    }
    
    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.handleCloseModal}
                    style={customStyles}
                    // base="portfolio-milestone-modal-base"
                    contentLabel={this.props.milestoneInfo.title}
                    overlayClassName="portfolio-milestone-modal-overlay"
                    className="portfolio-milestone-modal-content"
                    >
            
                    <div className="portfolio-modal-milestone-container">
                        <span onClick={this.handleCloseModal} className="dismiss-x-symbol"></span>
                        <h2 ref={subtitle => this.subtitle = subtitle} className="text-center portfolio-modal-milestone-title-text">{this.props.milestoneInfo.title}</h2>

                        <div className="container">
                            
                            <div className="row">
                                <div className={"col-xs-12 col-sm-12 col-md-8 col-lg-8      offset-md-2 offset-lg-2"}>
                                    {this.optionallyRenderMilestoneDemoVideos()}
                                </div>

                            </div>

                            <div className="row">
                                <div className={"col-md-8 offset-md-2"}>
                                    <p className="text-center portfolio-modal-milestone-description-text">{this.props.milestoneInfo.description}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

class ModalMilestoneVideo extends React.Component {

    optionallyRenderFooterTitle() {
        if (this.props.demoVideo.footerTitle !== undefined) {
            return (
                <div className="modal-milestone-video-footer-title-container">
                    <div className="container">
                        <div className="row">
                            <div className="">
                                <p className="modal-milestone-video-footer-text">{this.props.demoVideo.footerTitle}</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        if (this.props.demoVideo.isLocalVideo === true) {
            return (
                <div className={"portfolio-video-wrapper video-responsive col-xs-12 col-sm-12 col-md-" + this.props.desktopColumnWidth + " col-lg-" + this.props.desktopColumnWidth + " offset-md-" + this.props.centeredColumnOffset + " offset-lg-" + this.props.centeredColumnOffset}>
                    {/* <iframe className="" src={this.props.videoURL}></iframe> */}
                    {/* Expecting an mov file */}
                    <div>
                        <video autoPlay loop muted>
                            <source src={this.props.demoVideo.videoURL} type="video/mp4"/>
                            Your browser does not support HTML5 video.
                        </video>
                    </div>
                    <div>
                        {this.optionallyRenderFooterTitle()}
                    </div>
                </div>
                
            )
        } else {
            return (
                <div className={"portfolio-video-wrapper video-responsive col-xs-12 col-sm-12 col-md-" + this.props.desktopColumnWidth + " col-lg-" + this.props.desktopColumnWidth + " offset-md-" + this.props.centeredColumnOffset + " offset-lg-" + this.props.centeredColumnOffset}>
                    <iframe title={this.props.demoVideo.videoURL} className="" src={this.props.demoVideo.videoURL}></iframe>
                </div>
            )
        }
    }
}



















// Shows a list of languages used in this project. (Swift, etc.)
class UsedLanguagesList extends React.Component {
    optionallyRender() {
        if(this.props.languages_used) {
            return this.props.languages_used.map(function(language) {
                return (
                    <PortfolioLanguage language={language} />
                );
            });
        }
    }
    render() {
        return (
        <div>{this.optionallyRender()}</div>
        )
    }
}

class PortfolioLanguage extends React.Component {
    render() {
        return (
            <p className="text-center portfolio-languages-used-text"><b>{this.props.language}</b></p>
        );
    }
}

// Shows a list of technologies used in this project. (Firebase, etc.)
class UsedTechnologiesList extends React.Component {
    optionallyRender() {
        if(this.props.used_technologies) {
            return this.props.used_technologies.map(function(technology) {
                return (
                    <PortfolioTechnology technology_url={technology.url} technology_title={technology.title} />
                );
            });
        }
    }
    render() {
        return (
            <div>
                {this.optionallyRender()}
            </div>
        )
    }
}

class PortfolioTechnology extends React.Component {
   
    render() {
        return (
            <p className="text-center">
                <a 
                    className="portfolio-technologies-used-text" 
                    href={this.props.technology_url}>
                    <b>{this.props.technology_title}</b>
                </a>
            </p>
        );
    }
}

// Shows three columns explaining the project
class PortfolioDescriptionColumn extends React.Component {
   
    formatDescriptionText() {

        // This function converts any links to anchor tags
        // using the react-intl FormattedHTMLMessage component
        // TODO
        // Perhaps, this should be made globally available...

        const message = this.props.description_text;
        return (
            <p className="portfolio-about-text">
                <IntlProvider locale="en">                
                    <p><FormattedHTMLMessage id={message} message={message} /></p>
                </IntlProvider>
            </p>
        )
    }

    render() {



        return (
            <div className="col-xs-6 col-sm-6 col-md-4 col-lg-4">
                {this.formatDescriptionText()}
             </div>
        );
    }
}