
import { ProjectDocComponent } from './docs'

export const DocumentationSectionType = {
    text: "text",
    list: "list",
    markdown: "markdown",
    image: "image",
    video: "video",
};

export const DocumentationCodeblockLanguage = {
    swift: "swift",
    javascript: "javascript",
    html: "html",
    css: "css",
    ruby: "ruby"
}

const RootFilePath = `${process.env.PUBLIC_URL}`
const ImageCompletePath = (slug) => {
    // In case there's an unwanted slash
    if (slug[0] === '/') {
        slug = slug.substring(1)
    }
    return `${RootFilePath}/assets/images/docs/${slug}`
}

const VideoCompletePath = (slug) => {
    // In case there's an unwanted slash
    if(slug[0] === '/') {
        slug = slug.substring(1)
    }
    return `${RootFilePath}/assets/videos/docs/${slug}`
}

// matchPath allows for quick lookup from a nested child url
// Lets component know which "parent" to look through
// based on prefix of the url

export const DocsDrawerItems = {
    title: "Docs",
    data: [
        // SECTION Celestial
        {
            title: "Celestial",
            matchPath: "/docs/celestial",
            children: [
                {
                    repoURL: "https://www.github.com/ChrishonWyllie/Celestial",
                    repoPath: 'ChrishonWyllie/Celestial',
                    title: "Introduction",
                    url: "/docs/celestial",
                    description: `
                    `,
                    component: ProjectDocComponent,
                    documentation: [
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            Celestial is an in-app cache manager that allows you to easily cache both videos and images. 
                            You can use built-in UIViews [URLImageView](/docs/celestial/caching-images/urlimageview) and 
                            [URLVideoPlayerView](/docs/celestial/caching-videos/urlvideoplayerview) to display cached images
                            and videos respectively. These two UIView classes provide flexible options such as determining 
                            [where the downloaded image or video will be cached](/docs/celestial/cache-location/introduction)
                            
                            #### Avoid redundant downloads

                            Celestial's URLImageView and URLVideoPlayerView
                            prevents making duplicate API calls and addresses UI glitches when displaying
                            in dequeued UITableViewCells and UICollectionViewCells
                            
                            #### Prerequisites
                            
                                - Xcode 8.0 or higher
                                - iOS 10.0 or higher
                             
                            #### Installation
                            
                            Celestial is available through [CocoaPods](https://cocoapods.org/pods/Celestial). To install
                            it, simply add the following line to your Podfile:
                            
                            \`\`\`ruby
                            pod 'Celestial'
                            \`\`\`
                            `
                        },
                    ]
                },
                {
                    title: "Cache Location",
                    matchPath: "/docs/celestial/cache-location",
                    component: ProjectDocComponent,
                    children: [
                        {
                            title: "Introduction",
                            url: "/docs/celestial/cache-location/introduction",
                            description: `
                            CacheLocation determines where an image or video will be cached to
                            once its download completes. There are two options for where the
                            media can be cached to on the device: in memory and in the local file system
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    ### ResourceCacheLocation

                                    \`ResourceCacheLocation\` is an enum with two cases:

                                    - [Caching in memory](/docs/celestial/cache-location/caching-in-memory)
                                    - [Caching in File system](/docs/celestial/cache-location/caching-in-filesystem)
                                    
                                    It is used in the initializers of [URLImageView](/docs/celestial/caching-images/urlimageview)
                                    and [URLVideoPlayerView](/docs/celestial/caching-videos/urlvideoplayerview)
                                    `
                                }
                            ]
                        },
                        {
                            title: "Caching in Memory",
                            url: "/docs/celestial/cache-location/caching-in-memory",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,  
                                    text: `
                                    \`\`\`swift
                                    let cacheLocation: ResourceCacheLocation = .inMemory

                                    let imageView = URLImageView(delegate: nil, cacheLocation: cacheLocation)
                                    \`\`\`
                                    
                                    Caching media to memory will store them in a local [NSCache](https://developer.apple.com/documentation/foundation/nscache)
                                    using the URL pointing to the image or video as the key.
        
                                    This is the default cache location of the [URLImageView](/docs/celestial/caching-iamges/urlimageview),
                                    however, this can be changed in its initializer
        
                                    NSCache has default memory and item count limits imposed by iOS devices. 
                                    Once they receive memory warnings, they will automatically begin purging some of its contents, which
                                    will require Celestial to redownload that image or video.

                                    Cached images and videos will **NOT** persist across app sessions.
                                    For persisting acrossing app sessions, take a look at [Caching in Filesystem](/docs/celestial/cache-location/caching-in-filesystem)
        
                                    These limits can be configured, as explained below
                                    
                                    ---

                                    ##### Space Limits for Caching in Memory
        
                                    Sets the maximum cost of items that can be stored in either the video or image cache. 
                                    NOTE: This value is in number of bytes. 
                                    Use \`Int.OneMegabyte * <your value>\` or 
                                    \`Int.OneGigabyte * <your value>\` 
                                    This means that with each additional item stored in the cache, 
                                    the available space will decrease by the size of the item. 
                                    However, according to the [Apple documentation](https://developer.apple.com/documentation/foundation/nscache/1407672-totalcostlimit), this is not a strict limit. 
                                    Passing in nil for either argument will leave its respective cache unaffected
                                    and use the previous value or default value.
                                    
                                    \`\`\`swift
                                    public func setMemoryCacheCostLimits(videoCache: Int?, imageCache: Int?)
        
                                    // Example
        
                                    // Set limit of 200 megabytes of available space for videos
                                    let videoCacheLimit: Int = Int.OneMegabyte * 200
        
                                    // Set limit of 100 megabytes of available space for images
                                    let imageCacheLimit: Int = Int.OneMegabyte * 100
        
                                    Celestial.shared.setMemoryCacheCostLimit(videoCache: videoCacheLimit, imageCache: imageCacheLimit)
                                    \`\`\`

                                    ---

                                    ##### Item Limits for Caching in Memory
        
                                    Sets the maximum number of items that can be stored in either 
                                    the video or image cache. e.g., specifying 100 for videoCache 
                                    means at max, 100 videos may be stored. 
                                    However, according to the [Apple documentation](https://developer.apple.com/documentation/foundation/nscache/1416355-countlimit),
                                    this is not a strict limit. Passing in nil for either argument will 
                                    leave its respective cache unaffected and use the previous value or default value.
                                    
                                    \`\`\`swift
                                    public func setMemoryCacheItemLimits(videoCache: Int?, imageCache: Int?)
        
                                    // Example
        
                                    let videoCacheMaxItemCount: Int = 50
        
                                    let imageCacheMaxItemCount: Int = 100
        
                                    Celestial.shared.setMemoryCacheItemLimits(videoCache: videoCacheMaxItemCount, imageCache: imageCacheMaxItemCount)
                                    \`\`\`
                                    `
                                }
                            ]
                        },
                        {
                            title: "Caching in File System",
                            url: "/docs/celestial/cache-location/caching-in-filesystem",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Caching media to the file system will persist the data across app sessions.
                                    This allows for images and videos to be reused even if the app is fully terminated
                                    while avoiding making new API calls.

                                    This is default cache location of the [URLVideoPlayerView](/docs/celestial/caching-videos/urlvideoplayerview)
                                    due to the typically large nature of video files. However, this can be changed in its initializer.

                                    One caveat to caching to the file system, is that it may never be removed unless done manually.
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    ---

                                    ##### Removing media from file system

                                    Removing media from the file system can be done in multiple ways:
                                    
                                    ###### Removing media individually

                                    Since images and videos are stored with their URL as the key, pass
                                    in the original URL used to download the media to either function
                                    to delete the corresponding media file from local file system
                                    
                                    \`\`\`swift
                                    @discardableResult public func removeVideoFromFileCache(sourceURLString: String) -> Bool

                                    // Example
                                    
                                    let urlString = ...

                                    Celestial.shared.removeVideoFromFileCache(sourceURLString: urlString)

                                    Celestial.shared.removeImageFromFileCache(sourceURLString: urlString)
                                    \`\`\`

                                    ###### Removing all media
                                    
                                    \`\`\`swift
                                    // Clears all videos from memory cache and file system
                                    public func clearAllVideos()

                                    // Clears all images from memory cache and file system
                                    public func clearAllImages()
                                    \`\`\`
                                    `
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Caching Images",
                    matchPath: "/docs/celestial/caching-images",
                    children: [
                        {
                            title: "URLImageView",
                            url: "/docs/celestial/caching-images/urlimageview",
                            description: `
                            The URLImageView takes care of downloading images from external servers,
                            caching the image after the download completes, and of course displaying in 
                            its image property.
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    The simplest of the \`URLImageView\` initializers
                                    accepts a \`delegate\` [(More on this later)](/docs/celestial/delegate-events), 
                                    and the \`sourceURLString\`.
                                    Simply pass in the URL of the image, and it will be fetched and displayed.
                                    
                                    \`\`\`swift
                                    let urlString = "https://picsum.photos/400/800/?random"

                                    let imageView = URLImageView(delegate: nil, sourceURLString: urlString)
                                    imageView.contentMode = .scaleAspectFill
                                    imageView.layer.cornerRadius = 10
                                    \`\`\`
                                    `
                                },
                                {
                                    type: DocumentationSectionType.image,
                                    images: [
                                        ImageCompletePath('celestial/single-urlimageview.png')
                                    ]
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    #### Other Initializers
                                    ---

                                    While this simple example shows to load an image from external location,
                                    it does not demonstrate how this image has been cached and will be reused.

                                    Take a look at the displaying in cells [section](/docs/celestial/caching-images/displaying-in-cells)

                                    \`URLCachableViewDelegate\` is explained in the delegate events [section](/docs/celestial/delegate-events)
                                    
                                    \`ResourceCacheLocation\` is explained in the cache location [section](/docs/celestial/cache-location/introduction)
                                    
                                    \`\`\`swift
                                    public init(delegate: URLCachableViewDelegate?, 
                                                sourceURLString: String, 
                                                cacheLocation: ResourceCacheLocation = .inMemory)

                                    // Example

                                    let urlString = ...
                                    let cacheLocation: ResourceCacheLocation = .inMemory
                                    let imageView = URLImageView(delegate: nil, sourceURLString: urlString, cacheLocation: cacheLocation)
                                    \`\`\`

                                    \`\`\`swift
                                    public init(delegate: URLCachableViewDelegate?, cacheLocation: ResourceCacheLocation = .inMemory)
                                    \`\`\`

                                    \`\`\`swift
                                    public init(frame: CGRect, cacheLocation: ResourceCacheLocation)
                                    \`\`\`
                                    `
                                }
                            ]
                        },
                        {
                            title: "Displaying Images in Cells",
                            url: "/docs/celestial/caching-images/displaying-in-cells",
                            description: `
                            Displaying URLImageViews in dequeued cells is slightly different. 
                            Instead of passing the image URL into the initializer, you will 
                            manually load the image using the provided function below
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Pass in the URL pointing to the image on your external server, etc.
                                    when the cell is dequeued

                                    \`\`\`swift
                                    public func loadImageFrom(urlString: String)
                                    \`\`\`
                                    
                                    #### Step 1/3
                                    ---
                                    Define a \`URLImageView\` in your cell
                                    
                                    \`\`\`swift
                                    class ImageCell: UICollectionViewCell {

                                        let imageView = URLImageView(delegate: nil, cacheLocation: .inMemory)
                                        
                                        // Set up subviews, constraints, etc.

                                        func configure(with imageURLString: String) {
                                            imageView.loadImageFrom(urlString: imageURLString)
                                        }
                                    }
                                    \`\`\`

                                    #### Step 2/3
                                    ---
                                    Implement your UICollectionViewDataSource or UITableViewDataSource as usual
                                    
                                    \`\`\`swift
                                    struct Image {
                                        let urlString: String
                                    }

                                    class ViewController: UIViewController, UICollectionViewDataSource {

                                        let images: [Image] = self.fetchData()

                                        func fetchData() -> [Image] {
                                            // Fetch images from external server, etc.

                                            // reloadData
                                        }

                                        numberOfItems....

                                        cellForItemAt....
                                    }

                                    // ...
                                    \`\`\`

                                    #### Step 3/3
                                    ---
                                    When the \`ImageCell\` is dequeued, load your image from the string for that indexPath
                                    
                                    \`\`\`swift
                                    func collectionView(_ collectionView: UICollectionView, cellForItemAt indexPath: IndexPath) -> UICollectionViewCell {

                                        let cell = collectionView.dequeueReusableCell(withReuseIdentifier: cellReuseIdentifier, for: indexPath) as? ImageCell

                                        let imageURLString = images[indexPath.item].urlString

                                        cell?.configure(with: imageURLString)

                                        return cell ?? UICollectionViewCell()
                                    }

                                    // ...
                                    \`\`\`

                                    #### Example
                                    ---

                                    The images are fetched from external URLs, cached for reuse and
                                    displayed in the UICollectionViewCell.

                                    As the UICollectionView is scrolled back up, the cached images are
                                    reused instead of re-fetching them.
                                    `
                                },
                                {
                                    type: DocumentationSectionType.video,
                                    videos: [
                                        VideoCompletePath('celestial/urlimageview-cells-example.mp4')
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Caching Videos",
                    matchPath: "/docs/celestial/caching-videos",
                    children: [
                        {
                            title: "URLVideoPlayerView",
                            url: "/docs/celestial/caching-videos/urlvideoplayerview",
                            description: `
                            The URLVideoPlayerView downloads and displays videos from URLs all while caching them
                            from reuse
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    The simplest of the \`URLVideoPlayerView\` initializers
                                    accepts a \`delegate\` [(More on this later)](/docs/celestial/delegate-events), 
                                    and the \`sourceURLString\`.
                                    Simply pass in the URL of the video, and it will be fetched and displayed.
                                    
                                    \`\`\`swift
                                    let urlString = "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4"
                                    
                                    let playerView = URLVideoPlayerView(delegate: nil, sourceURLString: urlString)
                                    playerView.play()
                                    \`\`\`
                                    `
                                },
                                {
                                    type: DocumentationSectionType.image,
                                    images: [
                                        VideoCompletePath('celestial/single-urlvideoplayerview-example.mp4')
                                    ]
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    #### Other Initializers
                                    ---

                                    While this simple example shows to load a video from external location,
                                    it does not demonstrate how this video has been cached and will be reused.

                                    Take a look at the displaying in cells [section](/docs/celestial/caching-images/displaying-in-cells)

                                    \`URLVideoPlayerViewDelegate\` is explained in the delegate events [section](/docs/celestial/delegate-events)
                                    
                                    \`ResourceCacheLocation\` is explained in the cache location [section](/docs/celestial/cache-location/introduction)

                                    \`VideoExportQuality\` is explained in the properties [section](/docs/celestial/caching-videos/videoplayerview-properties)
                                    
                                    \`\`\`swift
                                    public convenience init(delegate: URLVideoPlayerViewDelegate?,
                                                            sourceURLString: String,
                                                            cacheLocation: ResourceCacheLocation,
                                                            videoExportQuality: Celestial.VideoExportQuality = .default)

                                    // Example

                                    let urlString = ...
                                    let cacheLocation: ResourceCacheLocation = .fileSystem
                                    let imageView = URLPlayerVideoView(delegate: nil, sourceURLString: urlString, cacheLocation: cacheLocation)
                                    \`\`\`
                                    
                                    \`\`\`swift
                                    public init(delegate: URLVideoPlayerViewDelegate?, cacheLocation: ResourceCacheLocation = .fileSystem)
                                    \`\`\`

                                    \`\`\`swift
                                    public init(frame: CGRect, cacheLocation: ResourceCacheLocation)
                                    \`\`\`
                                    `
                                },
                            ]
                        },
                        {
                            title: "Displaying Videos in Cells",
                            url: "/docs/celestial/caching-videos/displaying-in-cells",
                            description: `
                            Displaying URLVideoPlayerViews in dequeued cells is slightly different. 
                            Instead of passing the image URL into the initializer, you will 
                            manually load the video using the provided function below
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Pass in the URL pointing to the video on your external server, etc.
                                    when the cell is dequeued

                                    \`\`\`swift
                                    public func loadVideoFrom(urlString: String)
                                    \`\`\`
                                    
                                    #### Step 1/3
                                    ---
                                    Define a \`URLVideoPlayerView\` in your cell

                                    In this example, the cell will have a UIButton for playing
                                    and pausing the video player
                                    
                                    \`\`\`swift
                                    class VideoCell: UICollectionViewCell {

                                        let playerView = URLVideoPlayerView(delegate: nil, cacheLocation: .fileSystem)
                                        
                                        private lazy var playButton: UIButton = {
                                            let btn = UIButton()
                                            btn.setTitle("Play", for: .normal)
                                            btn.addTarget(self, action: #selector(togglePlaying), for: UIControl.Event.touchUpInside)
                                            return btn
                                        }()

                                        // Set up subviews, constraints, etc.

                                        func configure(with imageURLString: String) {
                                            playerView.loadVideoFrom(urlString: imageURLString)
                                        }
                                        
                                        @objc private func togglePlaying() {
                                            playerView.isPlaying ? playerView.pause() : playerView.play()
                                            let newPlayButtonTitle = playerView.isPlaying ? "Pause" : "Play"
                                            playButton.setTitle(newPlayButtonTitle, for: UIControl.State.normal)
                                        }
                                    }
                                    \`\`\`

                                    #### Step 2/3
                                    ---
                                    Implement your UICollectionViewDataSource or UITableViewDataSource as usual
                                    
                                    \`\`\`swift
                                    struct Video {
                                        let urlString: String
                                    }

                                    class ViewController: UIViewController, UICollectionViewDataSource {

                                        let videos: [Video] = self.fetchData()

                                        func fetchData() -> [Video] {
                                            // Fetch videos from external server, etc.

                                            // reloadData
                                        }

                                        numberOfItems....

                                        cellForItemAt....
                                    }

                                    // ...
                                    \`\`\`

                                    #### Step 3/3
                                    ---
                                    When the \`VideoCell\` is dequeued, load your video from the string for that indexPath
                                    
                                    \`\`\`swift
                                    func collectionView(_ collectionView: UICollectionView, cellForItemAt indexPath: IndexPath) -> UICollectionViewCell {

                                        let cell = collectionView.dequeueReusableCell(withReuseIdentifier: cellReuseIdentifier, for: indexPath) as? VideoCell

                                        let videoURLString = videos[indexPath.item].urlString

                                        cell?.configure(with: videoURLString)

                                        return cell ?? UICollectionViewCell()
                                    }

                                    // ...
                                    \`\`\`

                                    #### Example
                                    ---

                                    The videos are fetched from external URLs, cached for reuse and
                                    displayed in the UICollectionViewCell.

                                    As the UICollectionView is scrolled back up, the cached videos are
                                    reused instead of re-fetching them.
                                    `
                                },
                                {
                                    type: DocumentationSectionType.video,
                                    videos: [
                                        VideoCompletePath('celestial/urlvideoplayerview-cells-example.mp4')
                                    ]
                                }
                            ]
                        },
                        {
                            title: "Properties",
                            url: "/docs/celestial/caching-videos/videoplayerview-properties",
                            description: `
                            The URLVideoPlayerView inherits some useful properties from its superclasses that can be used for a variety of actions
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    \`\`\`swift
                                    videoExportQuality: Celestial.VideoExportQuality?
                                    \`\`\`
                                    
                                    Defines the quality at which downloaded videos will be exported and displayed.

                                    \`default\` will not download and display the video as-is

                                    \`low\` and \`medium\` will apply appropriate compressions to provide a video with a smaller file size, at the cost of quality and resolution

                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    \`\`\`swift
                                    resolution: CGSize?
                                    \`\`\`
                                    
                                    The resolution of the video in pixel format (e.g. 1080 Width by 1980 Height for a vertical video or 9:16 format)
                                    - \`NOTE\`: This will be nil or zero until the video is ready to play

                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    \`\`\`swift
                                    aspectRatio: CGFloat?
                                    \`\`\`
                                    The aspect ratio of the video represented as a double value
                                    - \`NOTE\`: This will be nil or zero until the video is ready to play
                                   
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    \`\`\`swift
                                    public func requiredSizeFor(width: CGFloat) -> CGSize
                                    \`\`\`
                                    Returns the size required to fit within the given
                                    width that would maintain the proper aspect ratio.

                                    When combined with \`AVLayerVideoGravity.resizeAspect (The default setting for AVPlayerLayer)\` this function can be
                                    used to provide a self-sizing view that would prevent the video from cropping.
    
                                    `
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Global functions",
                    matchPath: "/docs/celestial/global-functions",
                    children: [
                        {
                            title: "Download State",
                            url: "/docs/celestial/global-functions/download-state",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `

                                    Provides the current download state of a requested resource

                                    \`\`\`swift
                                    func downloadState(for sourceURL: URL) -> DownloadTaskState
                                    \`\`\`
                                    
                                    ##### Parameters:
                                    \`sourceURL\`: The original external URL pointing to the media resource on the server, etc.
                                    ##### Returns:
                                       An enum case representing the current download state of a requested resource. See \`DownloadTaskState\` below for possible cases

                                    ##### Usage:

                                    \`\`\`swift
                                    let urlString = <external URL pointing to your media>
                                    guard let url = URL(string: urlString) else {
                                        return
                                    }
                                    
                                    let downloadState = Celestial.shared.downloadState(for: url)
                                
                                    switch downloadState {
                                    case .downloading: ...
                                    }
                                    \`\`\`
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `

                                        #### DownloadTaskState

                                        \`\`\`swift
                                        /// First state of a download before it begins
                                        case none = 0
                                        /// Download has been temporarily paused. May be resumed
                                        case paused
                                        /// Download is currently in progress
                                        case downloading
                                        /// Download has finished and stored to a temporary URL, waiting to be cached if desired
                                        case finished
                                        \`\`\`
                                    `
                                }
                            ]
                        },
                        {
                            title: "Starting a Download",
                            url: "/docs/celestial/global-functions/manually-starting-download",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Begins loading the requested resource using its URL

                                    \`\`\`swift
                                    func startDownload(for sourceURL: URL)
                                    \`\`\`
                                    
                                    
                                    ##### Parameters:
                                    \`sourceURL\`: The original external URL pointing to the media resource on the server, etc.
                                    
                                    ##### Usage:
                                    
                                    \`\`\`swift
                                    let urlString = <external URL pointing to your media>
                                    guard let url = URL(string: urlString) else {
                                        return
                                    }
                                    
                                    Celestial.shared.startDownload(for: url)
                                    \`\`\`
                                    `
                                },
                            ]
                        },
                        {
                            title: "Pausing a Download",
                            url: "/docs/celestial/global-functions/manually-pausing-download",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `

                                    Pauses the current download of the requested resource if a download was previously initiated

                                    \`\`\`swift
                                    func pauseDownload(for sourceURL: URL)
                                    \`\`\`
                                    
                                    ##### Parameters:
                                    \`sourceURL\`: The original external URL pointing to the media resource on the server, etc.
                                    
                                    ##### Usage:
                                    
                                    \`\`\`swift
                                    let urlString = <external URL pointing to your media>
                                    guard let url = URL(string: urlString) else {
                                        return
                                    }
                                    
                                    Celestial.shared.pauseDownload(for: url)
                                    
                                    \`\`\`
                                    `
                                },
                            ]
                        },
                        {
                            title: "Resuming a Download",
                            url: "/docs/celestial/global-functions/manually-resuming-download",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `

                                    Resumes download of the requested resource if a download was previously initiated and paused

                                    \`\`\`swift
                                    func resumeDownload(for sourceURL: URL)
                                    \`\`\`

                                    ##### Parameters:
                                    \`sourceURL\`: The original external URL pointing to the media resource on the server, etc.
                                    
                                    ##### Usage:
                                    
                                    \`\`\`swift
                                    let urlString = <external URL pointing to your media>
                                    guard let url = URL(string: urlString) else {
                                        return
                                    }
                                    
                                    Celestial.shared.resumeDownload(for: url)
                                    \`\`\`
                                    `
                                },
                            ]
                        },
                        {
                            title: "Cancelling a Download",
                            url: "/docs/celestial/global-functions/manually-cancelling-download",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `

                                    Cancels a current download for a requested resource if a download was previously initated

                                    \`\`\`swift
                                    func cancelDownload(for sourceURL: URL)
                                    \`\`\`
                                    
                                    
                                    ##### Parameters:
                                    \`sourceURL\`: The original external URL pointing to the media resource on the server, etc.
                                    
                                    ##### Usage:
                                    
                                    \`\`\`swift
                                    let urlString = <external URL pointing to your media>
                                    guard let url = URL(string: urlString) else {
                                        return
                                    }
                                    
                                    Celestial.shared.cancelDownload(for: url)
                                    \`\`\`
                                    `
                                }
                            ]
                        }
                    ],
                },
                {
                    title: "Delegate Events",
                    url: "/docs/celestial/delegate-events",
                    description: "",
                    component: ProjectDocComponent,
                    documentation: [
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            Celestial's [URLImageView](/docs/celestial/caching-images/urlimageview)
                            and [URLVideoPlayerView](/docs/celestial/caching-videos/urlvideoplayerview)
                            provide delegates for observing events throughout the process of downloading
                            and caching media. 

                            One exception:
                            The URLVideoPlayerView's \`URLVideoPlayerViewDelegate\` delegate subclasses the URLCachableViewDelegate and
                            provides extra functionality. See below
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            #### URLCachableViewDelegate
                            ---
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            
                             ##### Download Completion
                             ---

                             Notifies receiver that a download has completed

                            - Parameters:
                            - \`view\`: The view that will send delegate information to its receiver
                            - \`media\`: The media that just finished downloading. 
                                - For \`URLImageView\` images, this can be type-casted to a \`UIImage\`
                                - For \`URLVideoPlayerView\`, this can be type-casted to a URL
                            
                            However, this for formality. There is no need to manually set the image
                            or video in the respective URLImageView or URLVideoPlayerView once download completes
                            
                            \`\`\`swift
                            @objc optional func urlCachableView(_ view: URLCachableView, didFinishDownloading media: Any)
                            \`\`\`
                            `
                        },
                        {   
                            type: DocumentationSectionType.markdown,
                            text: `
                            ##### Download Progress
                            ---

                             Notifies receiver of download progress prior to completion

                            - Parameters:
                            - \`view\`: The view that will send delegate information to its receiver
                            - \`progress\`: Float value from 0.0 to 1.0 denoting current download progress of the requested resource
                            - \`humanReadableProgress\`: A more easily readable version of the progress parameter
                            
                            \`\`\`swift
                            @objc optional func urlCachableView(_ view: URLCachableView, 
                                                                downloadProgress progress: Float, 
                                                                humanReadableProgress: String)
                            \`\`\`
                            `
                        },
                        {   
                            type: DocumentationSectionType.markdown,
                            text: `
                            ##### Errors
                            ---

                             Notifies receiver of errors encountered during download and/or caching

                            - Parameters:
                            - \`view\`: The view that will send delegate information to its receiver
                            - \`error\`: Error encountered during download and possible caching
                            
                            \`\`\`swift
                            @objc optional func urlCachableView(_ view: URLCachableView, downloadFailedWith error: Error)
                            \`\`\`
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            #### Example

                            \`\`\`swift
                            class ImageCell: UICollectionViewCell {

                                let imageView = URLImageView(delegate: self, cacheLocation: .inMemory)

                                // ...
                            }

                            extension ImageCell: URLCachableViewDelegate {
    
                                func urlCachableView(_ view: URLCachableView, didFinishDownloading media: Any) {
                                    // Download completed!
                                }
                                
                                func urlCachableView(_ view: URLCachableView, downloadFailedWith error: Error) {
                                    // Inspect the error
                                }
                                
                                func urlCachableView(_ view: URLCachableView, downloadProgress progress: Float, humanReadableProgress: String) {
                                    // Update progress bar, UI, etc.
                                }
                            }
                            
                            \`\`\`
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            #### URLVideoPlayerViewDelegate
                            ---

                            Notifies receiver that the \`URLVideoPlayerView\` is ready to begin
                            playing the video

                            \`\`\`swift
                            @objc optional func urlVideoPlayerIsReadyToPlay(_ view: URLVideoPlayerView)
                            
                            \`\`\`
                            `
                        }
                    ]
                },
                {
                    title: "Prefetching",
                    url: "/docs/celestial/prefetching",
                    description: "",
                    component: ProjectDocComponent,
                    documentation: [
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            Aside from the previous section on Global Properties, Celestial offers two functions prefetching media and
                            cancelling those prefetch requests respectively. This is works in tandem
                            with the [UICollectionView](https://developer.apple.com/documentation/uikit/uicollectionviewdatasourceprefetching) 
                            and [UITableView](https://developer.apple.com/documentation/uikit/uitableviewdatasourceprefetching) prefetching protocols
                        
                            #### Downloads for Prefetched items
                            ---

                            The Apple prefetching protocol function notifies the receiver of cells that are about
                            to be displayed which gives Celestial the opportunity to begin or cancel asynchronous loading
                            of media.

                             Begins download on multiple requested resource
                            \`\`\`swift
                            public func prefetchResources(at urlStrings: [String])
                            \`\`\`

                            Pauses (and cancels if desired) downloads on multiple requested resources. 
                            \`\`\`swift
                            public func pausePrefetchingForResources(at urlStrings: [String], cancelCompletely: Bool)
                            \`\`\`

                            #### Example
                            ---

                            \`\`\`swift
                            
                            // Using generic struct to display each cell
                            struct CellModel {
                                let urlString: String
                            }

                            extension ViewController: UICollectionViewDataSourcePrefetching {
                                func collectionView(_ collectionView: UICollectionView, prefetchItemsAt indexPaths: [IndexPath]) {
                                    
                                    let prefetchedModels = getCellModels(at: indexPaths)
                                    let urlStrings = prefetchedModels.map { $0.urlString }
                                    
                                    Celestial.shared.prefetchResources(at: urlStrings)
                                }
                                
                                func collectionView(_ collectionView: UICollectionView, cancelPrefetchingForItemsAt indexPaths: [IndexPath]) {
                                    
                                    let prefetchedModels = getCellModels(at: indexPaths)
                                    let urlStrings = prefetchedModels.map { $0.urlString }
                                    
                                    Celestial.shared.pausePrefetchingForResources(at: urlStrings, cancelCompletely: false)
                                }

                                func getCellModels(at indexPaths: [IndexPath]) -> [CellModel] {
                                    // Return the cell items at these indexPaths ...
                                }
                            }
                            \`\`\`

                            
                            `
                        }
                    ]
                },
            ]
        },
        // SECTION ComposableDataSource
        {
            title: "ComposableDataSource",
            matchPath: "/docs/composabledatasource",
            children: [
                {
                    repoURL: "https://www.github.com/ChrishonWyllie/ComposableDataSource",
                    repoPath: 'ChrishonWyllie/ComposableDataSource',
                    title: "Introduction",
                    url: "/docs/composabledatasource",
                    description: `
                    ComposableDataSource wraps the typically verbose UICollectionView data source and
                    delegate implementation into a more neatly packed builder pattern
                    `,
                    component: ProjectDocComponent,
                    documentation: [
                        {
                            type: DocumentationSectionType.text,
                            text: "Chain your UICollectionView delegate calls one after another as needed:",
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            language: DocumentationCodeblockLanguage.swift,
                            text: `
                            let dataSource = ComposableCollectionDataSource(....)
                            // chain selection delegate function
                            // chain cell size delegate function
                            // ... and so on ...
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            #### Slim down your UIViewController 

                            Often, your ViewController becomes bloated with handling when implementing \`UICollectionViewDelegate\`, 
                            \`UICollectionViewDataSource\` and \`UICollectionViewDelegateFlowLayout\`,
                             etc. Tasks such as configuring dequeued cells, handling cell selection events, providing cell sizing, etc.
                             can require a lot of tedious code.
                            `,                            
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            language: DocumentationCodeblockLanguage.swift,
                            text: `

                            // The default way of implementing UICollectionViews

                            class ViewController: UIViewController: UICollectionViewDelegate, UICollectionViewDataSource, UICollectionViewDelegateFlowLayout {

                                numberOfItems...

                                cellForItem...

                                sizeForItem...

                                didSelectItem...

                                // ... Hundreds of more lines...
                            }
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: '## Prerequisites'
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            - Xcode 8.0 or higher
                            - iOS 10.0 or higher
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `   
                            ## Installation
                            
                            ComposableDataSource is available through [CocoaPods](https://cocoapods.org/pods/ComposableDataSource). To install
                            it, simply add the following line to your Podfile:
                            `
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            language: DocumentationCodeblockLanguage.ruby,
                            text: `
                            pod 'ComposableDataSource'
                            `
                        },
                    ]
                },
                {
                    title: "Getting Started",
                    url: "/docs/composabledatasource/getting-started",
                    description: "Getting started with ComposableDataSource requires three steps",
                    component: ProjectDocComponent,
                    documentation: [
                        {
                            type: DocumentationSectionType.text,
                            text: "Getting started with ComposableDataSource requires three steps",
                        },
                        {
                            type: DocumentationSectionType.markdown,
                            text: `
                            #### Creating ViewModels
                            
                            The ViewModel tells the \`ComposableDataSource\` what kind of cell or supplementary view
                            to register and dequeue, and what data to configure that cell or view with

                            #### Creating Views
                            
                            The View (Cell or SupplementaryView) displays the data you defined in your ViewModel

                            #### Creating a DataSource
                            
                            Finally, the datasource displays the cells configured with its corresponding ViewModel
                            `,
                        },
                    ]
                },
                {
                    title: "Creating ViewModels",
                    matchPath: "/docs/composabledatasource/viewmodels",
                    children: [
                        {
                            title: "Cell ViewModels",
                            url: "/docs/composabledatasource/viewmodels/cells",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.text,
                                    text: `
                                    The ViewModel defines a cell to be dequeued,
                                    and the data that will be used to configure the cell.\n
                                    (For example, we will build a list of images)
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Your View Model must conform to \`BaseCollectionCellModel\`
                                    and provide a subclass type of \`BaseComposableCollectionViewCell\`.\n
                                    #### NOTE
                                    \`AnyComposableCellClass\` is a typealias for \`BaseComposableCollectionViewCell.Type\`\n
                                    Define your Cell ViewModel like so:
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    import ComposableDataSource

                                    // View Model
                                    struct ImageViewModel: BaseCollectionCellModel {
            
                                        func getCellClass() -> AnyComposableCellClass {
                                            return ImageCell.self
                                        }
                                    
                                        let imageURLString: String
                                        
                                    }
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    You can ignore the error about \`ImageCell\` being undefined for now. We are going to create this in the next section\n

                                    [Creating Cells](/docs/composabledatasource/views/cells)
                                    `
                                },
                            ]
                        },
                        {
                            title: "Supplementary ViewModels",
                            url: "/docs/composabledatasource/viewmodels/supplementary",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Your Supplementary View Model must conform to \`BaseCollectionSupplementaryViewModel\`\n
                                    To fulfill requirements, it must provide a subclass of \`BaseComposableCollectionReusableView\` subclass. 
                                    and a \`viewKind\` which determines whether this is a supplementary HEADER or FOOTER.
                                    Return \`UICollectionView.elementKindSectionHeader\` or \`UICollectionView.elementKindSectionFooter\` respectively\n
                                    Similar to how you would normally use \`collectionView.register(_:,forSupplementaryViewOfKind:,withReuseIdentifier:)\`\n
                                    More on this later, but for now, define your Supplementary ViewModel like so:
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    import ComposableDataSource

                                    struct HeaderModel: BaseCollectionSupplementaryViewModel {
                                        var viewKind: String {
                                            return UICollectionView.elementKindSectionHeader
                                        }
                                        
                                        func getReusableViewClass() -> AnyComposableCollectionReusableViewClass {
                                            return MyChatroomSectionHeaderView.self
                                        }
                                        let title: String
                                    }
                                    `
                                },
                            ]
                        }
                    ]
                },
                {
                    title: "Creating Views",
                    matchPath: "/docs/composabledatasource/views",
                    children: [
                        {
                            title: "Creating Cells",
                            url: "/docs/composabledatasource/views/cells",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Your UICollectionViewCell must subclass \`BaseComposableCollectionViewCell\`.
                                    Additionally, take advantage of two overridable functions:
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    
                                    func configure(with item: BaseCollectionCellModel, at indexPath: IndexPath) {
        
                                    }
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    The configure(with:at:) function is an overridable function from the \`BaseComposableCollectionViewCell\`
                                    that is automatically called when the cell is dequeued with \`collectionView(_:cellForItemAt:)\`.\n
                                    Use this to decorate your cell with data.
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    
                                    func setupUIElements() {
        
                                    }
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    The setupUIElements() function is an overridable function from the \`BaseComposableCollectionViewCell\`
                                    that is automatically called when the cell is initialized. Add your subviews, constraints, etc. here.
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    import ComposableDataSource
        
                                    class ImageCell: BaseComposableCollectionViewCell {
        
                                        override func configure(with item: BaseCollectionCellModel, at indexPath: IndexPath) {
                                            
                                            // Decorate cell using image url
                                            let imageViewModel = item as! ImageViewModel
                                            let imageURLString = imageViewModel.imageURLString
                                            
                                            // Make API call to fetch image as usual...
                                            // Perhaps, caching should be involved but thats another story
                                            URLSession.shared....
                                        }
                                    
                                        override func setupUIElements() {
                                            super.setupUIElements()
                                    
                                            // Remember to call super.setupUIElements()
        
                                            // Use \`super.containerView\` instead of contentView to add your subviews
                                            // e.g.:
                                            // super.containerView.addSubview(mySubview)
                                        }
                                    
                                    }
                                    `
                                },
                            ]
                        },
                        {
                            title: "Creating SupplementaryViews",
                            url: "/docs/composabledatasource/views/supplementary",
                            description: "",
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    import ComposableDataSource

                                    class MySectionHeaderView: BaseComposableCollectionReusableView {
    
                                        let titleLabel: UILabel...

                                        override func configure(with item: BaseCollectionSupplementaryViewModel, at indexPath: IndexPath) {
                                            let item = item as! HeaderModel
                                            titleLabel.text = item.title
                                        }
                                        
                                        override func setupUIElements() {
                                            super.setupUIElements()

                                            // Remember to call super.setupUIElements()
        
                                            // Use \`super.containerView\` instead of adding your subviews directly to self
                                            // e.g.:
                                            // super.containerView.addSubview(titleLabel)
                                        }
                                    }
                                    `
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Setting up Datasource",
                    matchPath: "/docs/composabledatasource/datasource",
                    children: [
                        {
                            title: "Initializing a Datasource",
                            url: "/docs/composabledatasource/datasource/initialization",
                            description: `
                            Initializing the datasource is the final step. 
                            Create your cell view models and supplementary view models if desired.
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    import ComposableDataSource
        
                                    private var collectionView: UICollectionView...
        
                                    private var collectionDataSource: ComposableCollectionDataSource?
        
                                    override func viewDidLoad() {
                                        super.viewDidLoad()
        
                                        collectionDataSource = setupDataSource()
                                    }
        
                                    private func setupDataSource() -> ComposableCollectionDataSource {
                
                                        // Initialize double nested array of view models
                                        // NOTE
                                        // Each inner array represents each section of your data source
                                        // in the order they are added
                                        let cellViewModels: [[ImageViewModel]] = [
                                            // Section 0
                                            [
                                                ImageViewModel(imageURLString: ....)
                                            ],
                                            // Section 1, etc....
                                        ]
                                        
                                        // Initialize array of supplementary models
                                        // NOTE
                                        // Each item represents the header and/or footer supplementary view
                                        // for a specific section in the order they are added
                                        
                                        let sectionModels: [BaseSupplementarySectionModel] = [
                                            // Section 0
                                            BaseSupplementarySectionModel(header: HeaderModel(title: "My Images"), footer: nil),
                                            // Section 1, etc. if desired. Not every section requires a supplementary view
                                            // Simply return CGSize.zero for sections with no supplementary view
                                        ]
                                        
                                        let dataSource = ComposableCollectionDataSource(collectionView: collectionView,
                                                                                        cellItems: cellViewModels,
                                                                                        supplementarySectionItems: sectionModels)
                                        
                                        return dataSource
                                    }
                                    `
                                },
                                
                            ]
                        }, 
                        {
                            title: "Delegate Chaining",
                            url: "/docs/composabledatasource/datasource/delegate-chaining",
                            description: `
                            `,
                            component: ProjectDocComponent,
                            documentation: [
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    ComposableDataSource provides wrappers for \`UICollectionViewDelegate\`, \`UICollectionViewDataSource\`
                                    and \`UICollectionViewDataSourcePrefetching\` delegate functions that return \`self\`.
                                    This allows for chaining together completion blocks
                                    for providing the datasource with cell/supplementary view sizes, handling (de)selection events,
                                    prefetching and more.
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    language: DocumentationCodeblockLanguage.swift,
                                    text: `
                                    import ComposableDataSource

                                    private func setupDataSource() -> ComposableCollectionDataSource {
                
                                        // Setup from previous section...
                                        
                                        let dataSource = ComposableCollectionDataSource(collectionView: collectionView,
                                                                                        cellItems: cellViewModels,
                                                                                        supplementarySectionItems: sectionModels)
                                        .didSelectItem { (indexPath: IndexPath, model: BaseCollectionCellModel) in
                                            
                                            // Handle selection at indexPath using the model
                                            
                                        }.sizeForItem { [unowned self] (indexPath: IndexPath, model: BaseCollectionCellModel) -> CGSize in
                                            
                                            // Return size for cell at the specified indexPath
                                        
                                        }.referenceSizeForHeader { [unowned self] (section: Int, model: BaseCollectionSupplementaryViewModel) -> CGSize in
                                        
                                            // Return size for supplementary header view at the specified indexPath
                                            // If your data source will have supplementary models 
                                            //
                                            // If there should be no supplementary view at this section, 
                                            // return CGSize.zero
                                        }
                                        // Chain more handlers ...
                                        
                                        return dataSource
                                    }
                                    `
                                },
                                {
                                    type: DocumentationSectionType.markdown,
                                    text: `
                                    Take a look at the complete list of chaining options
                                    `
                                },
                            ]
                        },
                        {
                            title: "Chain Handlers",
                            matchPath: "/docs/composabledatasource/datasource/chaining-handlers",
                            children: [
                                {
                                    title: "Selection",
                                    url: "/docs/composabledatasource/datasource/chaining-handlers/selection",
                                    description: `
                                    `,
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            #### Cell Selection
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            didSelectItem(_ completion: (IndexPath, BaseCollectionCellModel) -> ()) -> ComposableCollectionDataSource

                                            // Example
                                            
                                            let dataSource = ComposableCollectionDataSource(...)
                                            .didSelectItem { (indexPath: IndexPath, model: BaseCollectionCellModel) in
                                                if let imageViewModel = model as? ImageViewModel {
                                                    // Display image in large screen?
                                                }
                                            }

                                            return dataSource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            #### Cell Deselection
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            didDeselectItem(_ completion: (IndexPath, BaseCollectionCellModel) -> ()) -> ComposableCollectionDataSource
        
                                            // Example
                                            
                                            let dataSource = ComposableCollectionDataSource(...)
                                            .didDeselectItem { (indexPath: IndexPath, model: BaseCollectionCellModel) in
                                                if let imageViewModel = model as? ImageViewModel {
                                                    // Display image in large screen?
                                                }
                                            }
        
                                            return dataSource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            Completion block which returns the \`IndexPath\` and \`BaseCollectionCellModel\`
                                            for cell that was selected or deselected. Perform other desired actions (navigation, etc.)
                                            `
                                        },
                                    ]
                                },
                                {
                                    title: "Cell Sizing",
                                    url: "/docs/composabledatasource/datasource/chaining-handlers/cell-sizing",
                                    description: `
                                    `,
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            Completion block which provides a \`IndexPath\` and \`BaseCollectionCellModel\`
                                            for a dequeued cell requesting a \`CGSize\`
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            sizeForItem(_ completion: (IndexPath, BaseCollectionCellModel) -> (CGSize)) -> ComposableCollectionDataSource
                                            
                                            // Example

                                            let dataSource = ComposableCollectionDataSource(...)
                                            .sizeForItem { (indexPath: IndexPath, model: BaseCollectionCellModel) -> CGSize in
                                                if indexPath.section == 0 {
                                                    return CGSize(width: 100, height: 100)
                                                } else {
                                                    return CGSize(width: 300, height: 300)
                                                }
                                            }
        
                                            return dataSource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            Completion block which must return a \`CGSize\` for cell at specified \`IndexPath\`
                                            For extra flexibility, the \`BaseCollectionCellModel\` represented by the cell
                                            can be used for more complex size calculations, if necessary.
                                            `
                                        },
                                    ]
                                },
                                {
                                    title: "Supplementary View Sizing",
                                    url: "/docs/composabledatasource/datasource/chaining-handlers/supplementary-view-sizing",
                                    description: `
                                    `,
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            #### Header View
                                            Completion block which returns the section \`Int\` and \`BaseCollectionSupplementaryViewModel\`
                                            for a dequeued supplementary view requesting a \`CGSize\`
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            referenceSizeForHeader(_ completion: (Int, BaseCollectionSupplementaryViewModel) -> (CGSize)) -> ComposableCollectionDataSource
                                            
                                            // Example

                                            let dataSource = ComposableCollectionDataSource(...)
                                            .referenceSizeForHeader { (sectionIndex: Int, model: BaseCollectionSupplementaryViewModel) -> CGSize in
                                                if sectionIndex == 0 {
                                                    return CGSize(width: collectionView.frame.width, height: 50)
                                                } else {
                                                    return CGSize.zero
                                                }
                                            }
        
                                            return dataSource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            #### Footer View
                                            Completion block which returns the section \`Int\` and \`BaseCollectionSupplementaryViewModel\`
                                            for a dequeued supplementary view requesting a \`CGSize\`
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            referenceSizeForFooter(_ completion: (Int, BaseCollectionSupplementaryViewModel) -> (CGSize)) -> ComposableCollectionDataSource
                                            
                                            // Example

                                            let dataSource = ComposableCollectionDataSource(...)
                                            .referenceSizeForFooter { (sectionIndex: Int, model: BaseCollectionSupplementaryViewModel) -> CGSize in
                                                if sectionIndex == 0 {
                                                    return CGSize(width: collectionView.frame.width, height: 50)
                                                } else {
                                                    return CGSize.zero
                                                }
                                            }
        
                                            return dataSource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            Completion block which must return a \`CGSize\` for cell at specified section \`Int\`
                                            For extra flexibility, the \`BaseCollectionSupplementaryViewModel\` represented by the supplementary view
                                            can be used for more complex size calculations, if necessary.\n

                                            Return \`CGSize.zero\` if you did not provide a BaseCollectionSupplementaryViewModel for the section
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            Of course it should be noted that some sections may contain only a header, only a footer, both or none.\n
                                            This all depends on the SupplementaryViewModels you've added to your \`ComposableDataSource\`
                                            `
                                        },
                                    ]
                                },
                                {
                                    title: "Prefetching",
                                    url: "/docs/composabledatasource/datasource/chaining-handlers/prefetching",
                                    description: `
                                    `,
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            #### Requested Prefetching
                                            Completion block which returns \`[IndexPath]\` and \`[BaseCollectionCellModel]\`
                                            for requested prefetched items. Use completion block to perform operations on list
                                            of requested items (Loading images from URLs, etc.)
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            prefetchItems(_ completion: ([IndexPath], [BaseCollectionCellModel]) -> ()) -> ComposableCollectionDataSource
                                            
                                            // Example

                                            let dataSource = ComposableCollectionDataSource(...)
                                            .prefetchItems { (indexPaths: [IndexPath], models: [BaseCollectionCellModel]) -> CGSize in
                                                models.forEach ({ (model) in
                                                    if let imageViewModel = model as? ImageViewModel {
                                                        let imageURLString = imageViewModel.imageURLString

                                                        // Begin pre-downloading image at URL...
                                                    }
                                                })
                                            }
        
                                            return dataSource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            #### Cancelled Prefetching
                                            Completion block which returns \`[IndexPath]\` and \`[BaseCollectionCellModel]\`
                                            for cancelled prefetched items. Use completion block to cancel any previously started operations 
                                            of prefetched items (Such as cancelling loading of images from URLs, etc.)
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            cancelPrefetchingForItems(_ completion: ([IndexPath], [BaseCollectionCellModel]) -> ()) -> ComposableCollectionDataSource
                                            
                                            // Example

                                            let dataSource = ComposableCollectionDataSource(...)
                                            .cancelPrefetchingForItems { (indexPaths: [IndexPath], models: [BaseCollectionCellModel]) -> CGSize in
                                                models.forEach ({ (model) in
                                                    if let imageViewModel = model as? ImageViewModel {
                                                        let imageURLString = imageViewModel.imageURLString

                                                        // Stop pre-downloading image at URL...
                                                    }
                                                })
                                            }
        
                                            return dataSource
                                            `
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            title: "C.R.U. and Deleting ViewModels",
                            matchPath: "/docs/composabledatasource/datasource/crud",
                            children: [
                                {
                                    title: "Inserting ViewModels",
                                    url: "/docs/composabledatasource/datasource/crud/inserting",
                                    description: "Inserting ViewModels can be done in multiple ways",
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Inserting cell ViewModels 

                                            Cell ViewModels can be inserted individually at different IndexPaths
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func insert(cellItems: [T], 
                                                        atIndexPaths indexPaths: [IndexPath], 
                                                        updateStyle: DataSourceUpdateStyle, 
                                                        completion: OptionalCompletionHandler)

                                            // Example

                                            let newImageViewModels: [ImageViewModel] = ....
                                            let indexPaths: [IndexPath] = ....
                                            self.dataSource.insert(cellItems: newImageViewModels, atIndexPaths: indexPaths, 
                                                completion: { (_) in
                                                
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Inserting supplementary ViewModels 

                                            Similarly, supplementary ViewModels can be inserted individually at different section indices
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func insert(supplementarySectionItems: [S], 
                                                        atSections sections: [Int],
                                                        updateStyle: DataSourceUpdateStyle = .withBatchUpdates, 
                                                        completion: OptionalCompletionHandler)

                                            // Example

                                            let headerModel = HeaderViewModel(title: "Videos")
                                            let footerModel = ...
                                            let supplementarySectionItems: [BaseSupplementarySectionModel] = [
                                                BaseSupplementarySectionModel(header: headerModel, footer: footerModel)
                                            ]
                                            
                                            let sections: [Int] = [1]

                                            dataSource.insert(supplementarySectionItems: supplementarySectionItems,
                                                        atSections: sections, 
                                                        completion: { (_) in
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Inserting entire new sections

                                            Inserting new sections will insert an array of cell ViewModels and an 

                                            optional supplementary ViewModel at the desired section
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func insertNewSection(withCellItems cellItems: [T], 
                                                                    supplementarySectionItem: S? = nil,
                                                                    atSection section: Int,
                                                                    updateStyle: DataSourceUpdateStyle = .withBatchUpdates,
                                                                    completion: OptionalCompletionHandler)

                                            // Example
                                            
                                            let videoViewModels: [VideoViewModel] = [
                                                VideoViewModel(...),
                                                // ...
                                            ]

                                            let headerModel = HeaderViewModel(title: "Videos")
                                            let supplementarySectionItem = BaseSupplementarySectionModel(header: headerModel, footer: nil)
                                            let section: Int = 1

                                            dataSource.insertNewSection(withCellItems: videoViewModels, 
                                                        supplementarySectionItem: supplementarySectionItem, 
                                                        atSection: seection, 
                                                        completion: { (_) in
                                                // ...
                                            })
                                            `
                                        }
                                    ]
                                },
                                {
                                    title: "Reading ViewModels",
                                    url: "/docs/composabledatasource/datasource/crud/reading",
                                    description: "You can retrieve ViewModels by their IndexPath or section index",
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Getting Cell ViewModels
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func item(atIndexPath indexPath: IndexPath) -> T?

                                            // Example
                                            
                                            let indexPath: IndexPath = ...
        
                                            if let viewModel = dataSource.item(atIndexPath: indexPath) as? ImageViewModel {
                                                // ...
                                            }
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            public func allCellItems() -> [[T]]

                                            // Returns all cell view models in the dataSource
                                            
                                            public func cellModels(inSection section: Int) -> [T]

                                            // Returns cell view models in a desired section
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Getting Supplementary ViewModels
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func supplementarySectionItem(atSection section: Int) -> S?

                                            // Example
                                            
                                            let section: Int = ...
        
                                            if let supplementaryViewModel = dataSource.supplementarySectionItem(atSection: section) {
                                                if let header = supplementaryViewModel.header as? HeaderItemModel {
                                                    // ...
                                                }
                                                
                                                if let footer = supplementaryViewModel.footer as? ... {
                                                    // ...
                                                }
                                            }
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            public func allSupplementarySectionItems() -> [S]

                                            // Simply returns all the supplementary items for each section. 
                                            `
                                        },
                                    ]
                                },
                                {
                                    title: "Updating ViewModels",
                                    url: "/docs/composabledatasource/datasource/crud/updating",
                                    description: "Updating ViewModels can be done in multiple ways",
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Updating cell ViewModels 

                                            Cell ViewModels can be updated individually at different IndexPaths
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func update(atIndexPaths indexPaths: [IndexPath], 
                                                        newCellItems: [T],
                                                        updateStyle: DataSourceUpdateStyle, 
                                                        completion: OptionalCompletionHandler)

                                            // Example

                                            let newImageViewModels: [ImageViewModel] = ....
                                            let indexPaths: [IndexPath] = ....

                                            self.dataSource.update(atIndexPaths: indexPaths, cellItems: newImageViewModels, completion: { (_) in
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Inserting supplementary ViewModels 

                                            Similarly, supplementary ViewModels can be updated individually at different section indices
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func insert(atSections sections: [Int],
                                                        withNewSupplementarySectionItems: [S],
                                                        updateStyle: DataSourceUpdateStyle = .withBatchUpdates, 
                                                        completion: OptionalCompletionHandler)

                                            // Example

                                            let headerModel = HeaderViewModel(title: ...)
                                            let footerModel = ...
                                            let newSupplementarySectionItems: [BaseSupplementarySectionModel] = [
                                                BaseSupplementarySectionModel(header: headerModel, footer: footerModel)
                                            ]
                                            
                                            let sections: [Int] = [1]

                                            dataSource.updateSupplementarySectionsItems(atSections: sections, 
                                                        withNewSupplementarySectionItems: newSupplementarySectionItems, 
                                                        completion: { (_) in
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Updating entire sections

                                            Updating new sections will replace an existing section with new cell ViewModels and an 

                                            optional supplementary ViewModel at the desired sections.
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func updateSections(atItemSectionIndices itemSectionIndices: [Int],
                                                                newCellItems: [[T]],
                                                                supplementarySectionIndices: [Int]?,
                                                                supplementarySectionItems: [S]?,
                                                                updateStyle: DataSourceUpdateStyle,
                                                                completion: OptionalCompletionHandler)

                                            // Example
                                            
                                            let sections: [Int] = [0, 1]

                                            let newSupplementarySectionItems: [BaseSupplementarySectionModel] = [
                                                // Section 0
                                                BaseSupplementarySectionModel(header: HeaderViewModel(title: ...), footer: nil),
                                                // Section 1
                                                BaseSupplementarySectionModel(header: HeaderViewModel(title: ...), footer: nil)
                                            ]

                                            let videoModels: [[T]] = [
                                                [
                                                    // Section 0
                                                    ImageViewModel(...),
                                                    ImageViewModel(...)
                                                ],
                                                [
                                                    // Section 1
                                                    // ...
                                                ]
                                            ]

                                            dataSource.updateSections(atItemSectionIndices: sections,
                                                        newCellItems: videoModels,
                                                        supplementarySectionIndices: sections,
                                                        supplementarySectionItems: [supplementarySectionItem],
                                                        completion: { (_) in
                                            
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Replacing entire DataSource

                                            You can completely purge your DataSource and replace it with a new set of data.

                                            This is similar to updating entire sections, but with no need of section indices.
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func replaceDataSource(withCellItems cellItems: [[T]], supplementarySectionItems: [S])

                                            // Example
                                            
                                            let newSupplementarySectionItems: [BaseSupplementarySectionModel] = [
                                                // Section 0
                                                BaseSupplementarySectionModel(header: HeaderViewModel(title: ...), footer: nil),
                                                // Section 1
                                                BaseSupplementarySectionModel(header: HeaderViewModel(title: ...), footer: nil)
                                            ]

                                            let videoModels: [[T]] = [
                                                [
                                                    // Section 0
                                                    ImageViewModel(...),
                                                    ImageViewModel(...)
                                                ],
                                                [
                                                    // Section 1
                                                    // ...
                                                ]
                                            ]

                                            dataSource.replaceDataSource(withCellItems: viewModels,
                                                        supplementarySectionItems: newSupplementarySectionItems,
                                                        completion: { (_) in
                                                // ...
                                            })
                                            `
                                        }
                                    ]
                                },
                                {
                                    title: "Deleting ViewModels",
                                    url: "/docs/composabledatasource/datasource/crud/deleting",
                                    description: "Deleting ViewModels can be done in multiple ways",
                                    component: ProjectDocComponent,
                                    documentation: [
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Deleting cell ViewModels 

                                            Cell ViewModels can be deleted individually at different IndexPaths
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func deleteCellItems(atIndexPaths indexPaths: [IndexPath], 
                                                                updateStyle: DataSourceUpdateStyle, 
                                                                completion: OptionalCompletionHandler)

                                            // Example

                                            let indexPaths: [IndexPath] = ....
                                            self.dataSource.deleteCellItems(atIndexPaths: indexPaths, completion: { (_) in
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Deleting supplementary ViewModels 

                                            Similarly, supplementary ViewModels can be deleted individually at different section indices
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func deleteSupplementarySectionItems(atSections sections: [Int],
                                                                    updateStyle: DataSourceUpdateStyle = .withBatchUpdates, 
                                                                    completion: OptionalCompletionHandler)

                                            // Example

                                            let sections: [Int] = [1]

                                            dataSource.deleteSupplementarySectionItems(atSections: sections, completion: { (_) in
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Deleting entire new sections

                                            Inserting new sections will insert an array of cell ViewModels and an 

                                            optional supplementary ViewModel at the desired section
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func deleteSections(atSectionIndices sections: [Int],
                                                                updateStyle: DataSourceUpdateStyle,
                                                                completion: OptionalCompletionHandler)

                                            // Example
                                            
                                            let sections: [Int] = [0, 3]

                                            dataSource.deleteSections(atSectionIndices: sections, completion: { (_) in
                                                // ...
                                            })
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            text: `
                                            ### Deleting all items in the DataSource

                                            You can completely purge the DataSource of all its Cell and Supplementary ViewModels

                                            \`keepingStructure\` will remove all the items in each section and leave empty sections as
                                            opposed to removing the sections altogther, thus maintaining the original structure of the datasource
                                            `
                                        },
                                        {
                                            type: DocumentationSectionType.markdown,
                                            language: DocumentationCodeblockLanguage.swift,
                                            text: `
                                            func reset(keepingStructure: Bool = true)

                                            // Example
                                            
                                            dataSource.reset()
                                            `
                                        },
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    title: "Empty backgroundView",
                    url: "/docs/composabledatasource/empty-background-view",
                    description: `
                    Displaying a background view for when your datasource
                    is empty is intuitive.
                    Define the view you would like to display and the rest is taken
                    care of
                    `,
                    component: ProjectDocComponent,
                    documentation: [
                        {
                            type: DocumentationSectionType.markdown,
                            language: DocumentationCodeblockLanguage.swift,
                            text: `
                            dataSource.emptyDataSourceView = createEmptybackgroundView()

                            func createEmptybackgroundView() -> UIView {
                                let emptyContainerView = UIView()
        
                                let emptyViewLabel = UILabel()
                                emptyViewLabel.translatesAutoresizingMaskIntoConstraints = false
                                emptyViewLabel.text = "Still loading data... :)"
                                emptyViewLabel.font = UIFont.boldSystemFont(ofSize: 25)
                                emptyViewLabel.numberOfLines = 0
                                emptyViewLabel.textAlignment = .center
                                
                                let activityView = UIActivityIndicatorView(style: UIActivityIndicatorView.Style.gray)
                                activityView.translatesAutoresizingMaskIntoConstraints = false
                                activityView.hidesWhenStopped = true
                                
                                emptyContainerView.addSubview(emptyViewLabel)
                                emptyContainerView.addSubview(activityView)
                                
                                let paddingConstant: CGFloat = 12.0
                                emptyViewLabel.leadingAnchor.constraint(equalTo: emptyContainerView.leadingAnchor, constant: paddingConstant).isActive = true
                                emptyViewLabel.trailingAnchor.constraint(equalTo: emptyContainerView.trailingAnchor, constant: -paddingConstant).isActive = true
                                emptyViewLabel.centerYAnchor.constraint(equalTo: emptyContainerView.centerYAnchor).isActive = true
                                emptyViewLabel.heightAnchor.constraint(equalToConstant: 50).isActive = true
                                
                                
                                activityView.topAnchor.constraint(equalTo: emptyViewLabel.bottomAnchor, constant: paddingConstant).isActive = true
                                activityView.centerXAnchor.constraint(equalTo: emptyContainerView.centerXAnchor).isActive = true
                                
                                activityView.startAnimating()

                                return emptyContainerView
                            }
                            `
                        },
                        {
                            type: DocumentationSectionType.image,
                            images: [
                                ImageCompletePath('composabledatasource/empty-background-view.png'),
                            ]
                        }
                    ]
                },
            ]
        },
        
    ]
}

