import React from 'react';
import '../css/index.css';
import { Link } from 'react-scroll'

export default class HeaderArea extends React.Component {
	
	render() {

		return (
			<div>
				{/* This span is used to avoid the issue of the navbar covering the elements in this div */}
				<span className="anchor-link" id="HomeLink"></span>
				<div className="header-background-parallax">

					<Link
						to="AboutLink"
						spy={true}
						smooth={true}
						offset={0}
						duration= {500}
					>
						<span id="headerScrollDownButton" className="header-scroll-down" address="true"></span>
					</Link>
					
					<div className="header-container container">
						
						<h2 className="text-center header-text header-first-text animated-element slide-left">Chrishon Wyllie</h2>
				
						<h3 className="text-center header-text header-second-text animated-element slide-right">Mobile and Web developer</h3>
					
					</div>
				</div>
			</div>
		);	
	}
}