import React from 'react';
import '../css/footer.css';

export default class FooterArea extends React.Component {
	render() {
		return (
			<div className="footer-background-color">
				<div className="container center">
					<div className="footer-row-top-margin row">
						
						<div className="footer-padding-50px">
							<p className="footer-text-color text-center">Made with love and React by Chrishon Wyllie <span className="glyphicon glyphicon-copyright-mark"></span> 2021</p>
							
							<br />
						
							<div className="row ">
								<div className="col">
									<a href="https://www.youtube.com/channel/UCeFMhLyAPWOVUQ3W0Esuvdw?view_as=subscriber"><i id="social-yt" className="fa fa-youtube-square fa-3x social"></i></a>
								</div>
							
								<div className="col">
									<a href="https://www.linkedin.com/in/chrishonwyllie/"><i id="social-tw" className="fa fa-linkedin-square fa-3x social"></i></a>
								</div>
							
								<div className="col">
									<a href="https://www.github.com/ChrishonWyllie"><i id="social-gp" className="fa fa-github-square fa-3x social"></i></a>
								</div>
							
								<div className="col">
									<a href="mailto:chrishonwyllie@gmail.com" target="_blank" rel="noopener noreferrer"><i id="social-em" className="fa fa-envelope-square fa-3x social"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
    		</div>
		);
	}
}