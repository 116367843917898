import React from 'react';
import '../css/index.css';
import SkillBar from 'react-skillbars';
// import '../css/animations.css';



const skills = [
	{type: "Swift", level: 95},
	{type: "Objective-C", level: 70},
	{type: "Java", level: 90},
	{type: "HTML", level: 90},
	{type: "CSS", level: 85},
	{type: "Javascript", level: 80},
	{type: "React JS", level: 80},
	{type: "PHP", level: 80},
	{type: "SQL", level: 85}
];

const skillbar_colors = {
	"bar": "#5318e4",
	"title": {
	  "text": "#fff",
	  "background": "#5926d6"
	}
}
  

export default class AboutArea extends React.Component {
	render() {

		return (
			<div>
				<span className="anchor-link" id="AboutLink"></span>
				<section className="header-scroll-destination global-cool-white-background">

					<div className="container global-cool-white-background">
					
						<h3 className="text-center section-title-header">About Me</h3>
					
						{/* Row One */}
						
						{/* Row Two (Top Four skills: iOS, Android, Responsive Web Design, UI/UX Design) */}
						
						<div className="row about-row">
							
							{/* iOS */}
							
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
							
								<i className="fa fa-apple fa-4x horizontal-center" />
								
								<h4 className="about-skill-title text-center">iOS</h4>
							
								<p className="about-skill-text text-center">
									I've developed and published a number of applications and utilized a variety
									of frameworks and APIs. I can design and implement iOS applications for virtually
									any business
								</p>
							</div>
							
							{/* Android */}
							
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
							
								<i className="fa fa-android fa-4x horizontal-center" />
							
								<h4 className="about-skill-title text-center">Android</h4>
							
								<p className="about-skill-text text-center">
									Years of experience in Java have made Android development very intuitive. I've
									shipped production level applications for both professional and personal purposes
									that mirror all features of their iOS counterparts
								</p>
							</div>
							
							{/* Responsive Web Design */}
							
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
							
								<i className="fa fa-desktop fa-4x horizontal-center" />
								
								<h4 className="about-skill-title text-center">Responsive Web Design</h4>
							
								<p className="about-skill-text text-center">
									A website is often the first impression, therefore I make it my utmost priority
									to design them well and with a mobile-first mindset. I can move a project from a
									patchy idea to a functional prototype by the end of the day
								</p>
							</div>
							
							{/* UI/UX Design */}
							
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
							
								<i className="fa fa-pencil fa-4x horizontal-center" />
								
								<h4 className="about-skill-title text-center">UI / UX Design</h4>
							
								<p className="about-skill-text text-center">
									Through my experience with iOS, Android and Web development, I have
									made user experience and interaction my utmost priority. I fully believe
									that a user-facing application or website must be intuitive and can
									be navigated with ease
								</p>
							</div>
						</div>
						
						
						
						{/* Row Three (My Skills: Swift, Java, etc.) */}
						
						<div className="row about-row">
							
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 animated-element-container">
							
								<img className="horizontal-center rounded-circle img-responsive about-profile-image" src={`${process.env.PUBLIC_URL}/assets/images/me/me_cropped.jpeg`} alt=""/>
							
								<p className="about-me-text text-center animated-element fade">
									Hiya! <span role="img" aria-label="wave">👋</span>, I'm an iOS engineer from the from the big 'ol apple
									currently working as an SDK Software Engineer at <a href="https://www.socketmobile.com">Socket Mobile, Inc.</a> For work, 
									I implement new features for our SDK/hardware products. Personally,
									I enjoy spending time building clones of popular apps and building useful
									open source frameworks. 
									See some of my work below
									
								</p>
							</div>
							
							<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
								<SkillBar skills={skills} colors={skillbar_colors} />
							</div>
							
						</div>
					</div>
				</section>
			</div>
		);	
	}
}