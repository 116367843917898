import React from 'react';
import '../css/index.css';

export default class WorkArea extends React.Component {
	render() {
		return (
			<div className="global-cool-white-background">
				{/* This span is used to avoid the issue of the navbar covering the elements in this div */}
				<span className="anchor-link" id="FreelanceWorkLink"></span>
				<section className="global-padding-topbottom-60px">
				
					<div>
						
						
						
						
						{/* Work */}
				
						<div className="work-container container">
							<h3 className="text-center section-title-header">My Work</h3>
					
					
   {/* -------------------- Next Gear Crew */}
							
							<div className="row global-margin-top-40px global-padding-top-bottom-40px global-padding-leftright-40px animated-element-container">
								<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
									
									<div className="row">
										{/* Apple App Store */}
										<div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
											<img className="work-mobile-image img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/ios/cropped-ios-device-screenshot-event-directions-screen_iphone8silver_portrait.png`} />
								
											<div className="form-group global-padding-20px center">
												<a className="apple-app-store-bttn" href="https://itunes.apple.com/us/app/next-gear-crew/id1183996071?mt=8">
													<i className="fa fa-apple fa-2x horizontal-center" />
												</a>
											</div>
										</div>
						
										{/* Google Play Store 8 */}
										<div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">									 
											<img className="work-mobile-image img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/android/cropped-android-device-screenshot-homescreen_pixel_very_silver_portrait.png`} />
								
											<div className="form-group global-padding-20px center">
												<a className="google-play-store-bttn" href="https://play.google.com/store/apps/details?id=com.chrishonwyllie.nextgearcrew">
													<i className="fa fa-android fa-2x horizontal-center" />
												</a>
											</div>
										</div>
									
									</div>
									
								</div>
								
								<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
									<h4 className="work-client-header">Next Gear Crew</h4>
								
									<h5 className="work-role-header">Fullstack Engineer</h5>
								
									<p className="work-client-description global-margin-top-20px">
										Next Gear Crew is a New York-based car club specializing in
										vehicle customizations (aesthetic and performance) as well as
										e-commerce retailer.
									</p>
									
									<div className="form-group global-padding-20px horizontal-center">
										<a className="" href="/nextgearcrew">
											<input type="button" value="See more" className="bttn bttn-rounded bttn-bordered box-shadow" />
										</a>
									</div>
									
								</div>
							</div>
							
							
							{/* Testimonial */}
							
							<div className="work-testimonial-container animated-element-container">
									
								<div className="work-testimonial-item work-testimonial-item-small animated-element fade">
									<img className="work-testimonial-image horizontal-center rounded-circle img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/me/me_cropped.jpeg`} />
									<p className="work-testimonial-name text-center">Wayne, Senior Admin, Next Gear Crew</p>
								</div>
								 
								<div className="work-testimonial-item work-testimonial-item-large animated-element fade">
									<p className="work-testimonial-text global-margin-top-20px">
										&#34;Chrishon has single-handedly built our mobile and web applications
											from scratch and provided us with a platform to better serve our customers.
											He has greatly exceeded our expectations and continues to do so with each
											new update.&#34;
									</p>
								</div>
								
							</div>
							
							
							
							{/* ---------------------------------------------------------------------------------------- */}
							
							<div className="row global-margin-topbottom-20px animated-element-container">
								<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 animated-element slide-up">
									<hr className="work-horizontal-rule" />
								</div>
							</div>
							
							{/* ---------------------------------------------------------------------------------------- */}
							
							
							
						
   {/* -------------------- Dalelectric.com */}
						
							<div className="row global-padding-leftright-40px animated-element-container">
							
								<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 work-text-container animated-element slide-up">
									
									<h4 className="work-client-header">Dal Electric</h4>
									
									<h5 className="work-role-header">Front-end Web developer</h5>
									
									<p className="work-client-description global-margin-top-20px">
										Dal Electric is a results-oriented
										electrical contractor that places the needs
										of its clients above all. They succeed in their 
										tasks regardless of the size of the project.
									</p>
									
									<div className="form-group global-padding-20px horizontal-center">
										<a className="" href="http://dalelectric-com.stackstaging.com/">
										   	<input type="button" value="Visit Website" className="bttn bttn-rounded bttn-bordered box-shadow" />
										</a>
									</div>
									
								</div>
							
								<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 animated-element slide-up">
									<img className="img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/work/dal-electric/work-dalelectric-final.png`} />
								</div>
							
							</div>
							
							
							{/* Testimonial */}
							
							<div className="work-testimonial-container animated-element-container">
									
								<div className="work-testimonial-item work-testimonial-item-small animated-element fade">
									<img className="work-testimonial-image horizontal-center rounded-circle img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/work/dal-electric/sal-dalessio-square.jpg`} />
									{/* The " &#39; " is HTML-code for an apostrophe. Otherwise, it would be registered as a string */} 
									<p className="work-testimonial-name text-center">Sal D&#39;Alessio, CEO, Dal Electric</p>
								</div>
								 
								<div className="work-testimonial-item work-testimonial-item-large animated-element fade">
									<p className="work-testimonial-text global-margin-top-20px">
										&#34;Chrishon has brought our digital presence to life by 
										creating and designing our very first website in company history. 
										He has done excellent work and we will continue to work with him
										in the future&#34;
									</p>
								</div>
								
							</div>
							
							
							
							{/* ---------------------------------------------------------------------------------------- */}
							
							<div className="row global-margin-topbottom-20px animated-element-container">
								<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 animated-element slide-up">
									<hr className="work-horizontal-rule" />
								</div>
							</div>
							
							{/* ---------------------------------------------------------------------------------------- */}
							
							
							
							
   {/* -------------------- Sabrinaaziz.com */}
						
							<div className="row global-margin-top-40px global-padding-leftright-40px animated-element-container">
							
								<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 animated-element slide-up">
									<img className="img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/work/sabrina-aziz/work-sabrinaaziz-final.png`} />
								</div>
								
								<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 work-text-container animated-element slide-up">
									
									<h4 className="work-client-header">Sabrina Aziz</h4>
									
									<h5 className="work-role-header">Front-end Web developer</h5>
									
									<p className="work-client-description global-margin-top-20px">
										My good friend and actress, Sabrina approached me
										with plans to acquire her own website to display
										her acting chops and hobbies. Sabrina has extensive
										experience in both theater and film settings as well as
										many a variety of stage combat certifications. 
										She&#39;s perhaps the hardest-working person 
										I&#39;ve had the pleasure of 
										meeting and is surely on her way to stardom. 
									</p>
									
									<div className="form-group global-padding-20px horizontal-center">
										<a className="" href="http://www.sabrinaaziz.com/">
										   	<input type="button" value="Visit Website" className="bttn bttn-rounded bttn-bordered box-shadow" />
										</a>
									</div>
									
								</div>
							
							</div>
							
							
							{/* Testimonial */}
							
							<div className="work-testimonial-container animated-element-container">
									
								<div className="work-testimonial-item work-testimonial-item-small animated-element fade">
									<img className="work-testimonial-image horizontal-center rounded-circle img-responsive" alt="" src={`${process.env.PUBLIC_URL}/assets/images/work/sabrina-aziz/sabrina-aziz-headshot-square.jpg`} />
									<p className="work-testimonial-name text-center">Sabrina Aziz, NYC-based film and theater actress</p>
								</div>
								 
								<div className="work-testimonial-item work-testimonial-item-large animated-element fade">
									<p className="work-testimonial-text global-margin-top-20px">
										&#34;One day, Chrishon and I were discussing my desire to 
										seriously <i>get myself out there</i>, and he suggested that
										I should also have a portfolio website. To my surprise and delight,
										he sent me a prototype that same day&#34;
									</p>
								</div>
								
							</div>
							
						
						</div>
						
					</div>
				
				</section>
				
				
				
				
				{/* Bottom pointed section */}
				
				<section className="svg-container-section bottom-pointed-section">
					
					{/* The "viewBox" property allows the use of percentages for polygon points for responsive effect */}
					<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 100 100" preserveAspectRatio="none" className="svg-triangle">
					  	<polygon points="0,0 100,0 50,100"/>
					</svg>
					
					{/*
					<svg width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none" style='background-color: whitesmoke'>
						<rect x='40%' y='40%' width='25%' height='25%' />
		
						<polygon points="0,0 0,100 30,20 30,0" />
						<polygon points="30,0 30,20 60,0 60,0" />
						<polygon points="60,0 60,0 90,30 90,0" />
					</svg>
					*/}
				</section>
			</div>
		);
	}
}
