import React from 'react';
import uuid from 'react-uuid';
import '../css/navbar.css';

import { Link as ReactScrollLink } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom';

export class NavBar extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			brand: {linkTo: "/", text: "Chrishon Wyllie"}
		}
	}

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }
	
	componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
      
    handleScroll(event) {
		
		var navbar = document.getElementsByClassName('navbar navbar-expand-md navbar-light navbar-custom fixed-top')[0];
		var navbar_brand = document.getElementsByClassName('navbar-brand')[0];
		var navbar_list_items = document.getElementsByClassName('navbar-menu-item-link');

		// NOTE
		// For some reason, the navbar brand does not toggle classes
		// when on the home page. It only does it when in one of the 
		// portfolios
		// This result in the text changing from white to purple.
		// But not on the home page.
		// 10/20/19
		
		const windowScrollPosition =
			document.body.scrollTop || document.documentElement.scrollTop

		var startY = navbar.clientHeight * 1.5; //The point where the navbar changes in px

		if (windowScrollPosition > startY) {
			navbar.classList.add("scrolled");
			navbar_brand.classList.add("scrolled");

			Array.prototype.forEach.call(navbar_list_items, function(element) {
				element.classList.add("scrolled");
			});
			// $('li > a').removeClass('.active');
		} else {
			navbar.classList.remove("scrolled");
			navbar_brand.classList.remove("scrolled");

			Array.prototype.forEach.call(navbar_list_items, function(element) {
				element.classList.remove("scrolled");
			});
			// $('li > a').addClass('.active');
		}
	}

	
	render() {

    	return (
			<nav className="navbar navbar-expand-md navbar-light navbar-custom fixed-top">
					
				<NavBrand linkTo={this.state.brand.linkTo} text={this.state.brand.text} />
						
				<button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbar-collapse" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
					
				<div className="navbar-collapse collapse" id="navbar-collapse">
					<ul className="nav navbar-nav navbar-right">
						<NavMenu links={this.props.links} />
					</ul>
				</div>
			</nav>
    	);
  	}
}

class NavBrand extends React.Component {
	render() {
    	return (
             <a className="navbar-brand" href={this.props.linkTo}>{this.props.text}</a>
    	);
  	}
}

class NavMenu extends React.Component {
	render() {

		var links = []

		if(this.props.links) {
			links = this.props.links.map(function(link) {
				if (link.dropdown) {
					return (
						<NavLinkDropdown key={uuid()} links={link.links} text={link.text} active={link.active} />
					);
				} else if (link.isExternalLink) {
					return (
						<ExternalNavLink key={uuid()} linkTo={link.linkTo} text={link.text} active={link.active} />
					);
				} else if (link.isURLComponentNavLink) {
					return (
						<URLComponentNavLink key={uuid()} linkTo={link.linkTo} text={link.text} />
					);
				} else {
					return (
						<InternalNavLink key={uuid()} linkTo={link.linkTo} text={link.text} active={link.active} />
					);
				}
			});
		}
			
		return (
			<ul className="nav navbar-nav">{links}</ul>
		);
	}
}

class NavLinkDropdown extends React.Component {
	render() {
        return (
            <div>
				{
				this.props.links.map((link, index) => {

					return (
						<InternalNavLink linkTo={link.linkTo} text={link.text} customStyle={link.customStyle} active={link.active} />
					);
				})
            	}
            </div>
        );
	}
}

export class ExternalNavLink extends React.Component {
	render() {
		return(
			<a href={this.props.linkTo} target="_blank" rel="noopener noreferrer">
				<p className="navbar-menu-item-link">{this.props.text}</p>
			</a>
		);
	}
}

export class InternalNavLink extends React.Component {
	render() {
    	return (
			<ReactScrollLink
                activeClass="active" // Find a way to change this link to another color when active
                to={this.props.linkTo}
                spy={true}
                smooth={true}
                offset={0}
				duration= {500}
			>
				<p className="navbar-menu-item-link">{this.props.text}</p>
			</ReactScrollLink>
    	);
  	}
}

export class URLComponentNavLink extends React.Component {
	render() {
    	return (
			<RouterLink to={this.props.linkTo}>
				<p className="navbar-menu-item-link">{this.props.text}</p>
			</RouterLink>
    	);
  	}
}