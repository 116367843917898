import React from 'react';
import './App.css';

import './css/navbar.css';
import './css/index.css';

// import NavBar from './components/navbar';
import FooterArea from './components/footer';

import Home from './components/home';
import { PortfolioDetailComponent } from './components/portfolio/portfoliodetailcomponent';
import { PortfolioProjectIdentifier } from './components/portfolio/portfoliodetaildescriptions';
import { DocsCentralHubComponent, DocsHomeComponent } from './components/docs/docs';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
 

function renderPortfolioRoutes() {

    const entries = Object.entries(PortfolioProjectIdentifier)
    return entries.map(([key, value]) => {
        return (
            <Route path={`/${value}`}>
                <PortfolioDetailComponent portfolioProjectIdentifier={key} />
            </Route>
        )
    });
} 

function renderDocsRoutes() {
    const routes = [
        <Route exact path='/docs/home' component={DocsHomeComponent} />,
        <Route path='/docs' component={DocsCentralHubComponent} />
    ]
    return routes
}

function App() {
  return (
    <div className="App">
       {/* TODO: Figure out how to react to changes in route and update
       the navbar links accordingly, instead of declaring the NavBar
       tag in every portfolio project */}
      {/* <NavBar links={[]} /> */}
      <Router>
          <div>
              <Switch>
                    <Route exact path='/'>
                        <Home />
                    </Route>
                    {renderPortfolioRoutes()}
                    {renderDocsRoutes()}
              </Switch>
          </div>
      </Router>
      <FooterArea />
    </div>
  );
}

export default App;
