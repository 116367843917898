import React from 'react';
import uuid from 'react-uuid';
import '../css/index.css';
import AnimatableCard from './animatablecard';

export class PortfolioProjects extends React.Component {
    
    render() {

        var portfolioProjects = [];

        if (this.props.portfolioProjects !== undefined) {
            portfolioProjects = this.props.portfolioProjects.map(function(project) {
                return (
                    <AnimatableCard key={uuid()} linkTo={project.linkTo} imageURL={project.imageURL} alternativeImageTitle={project.alternativeImageTitle} title={project.title} />
                );
            });
        } else {
            // This component was declared without the portfolioProjects identifier,
            // just show them all
            portfolioProjects = Object.values(PortfolioProjectInfo).map(function(project) {
                return (
                    <AnimatableCard key={uuid()} linkTo={project.linkTo} imageURL={project.imageURL} alternativeImageTitle={project.alternativeImageTitle} title={project.title} />
                );
            });
        }

        return (
            <div>
                <span className="anchor-link" id="PortfolioWorkLink"></span>
				<section className="other-projects-section">
					<div className="container">
						
						<h2 className="text-center">Other Projects</h2>

                        <div className="row portfolio-row">
                            {portfolioProjects}
                        </div>
                    </div>
                </section>
            </div>
            
        );
    }

}











export const PortfolioProjectInfo = {
    "snapchatclone": {
        linkTo:"/snapchatclone",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/projectzero/snapchat_app_icon.jpg`,
        alternativeImageTitle:"Snapchat clone",
        title:"Snapchat clone"
    },
    "celestial": {
        linkTo:"/celestial",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/Celestial-icon.png`,
        alternativeImageTitle:"Celestial",
        title:"Celestial"
    },
    "composabledatasource": {
        linkTo:"/composabledatasource",
        alternativeImageTitle:"ComposableDataSource",
        title:"ComposableDataSource"
    },
    "cwprogresshud": {
        linkTo:"/cwprogresshud",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/cwprogresshud/cwprogresshud_show_light.png`,
        alternativeImageTitle:"CWProgressHUD",
        title:"CWProgressHUD"
    },
    "modularsidebarview": {
        linkTo:"/modularsidebarview",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/modularsidebarview/final/ModularSidebarView_final_mockup.png`,
        alternativeImageTitle:"ModularSidebarView",
        title:"ModularSidebarView"
    },
    "brainstorm": {
        linkTo:"/brainstorm",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/brainstorm/brainstorm-portfolio-display-image.png`,
        alternativeImageTitle:"Brainstorm",
        title:"Brainstorm"
    },
    "chatlyne": {
        linkTo:"/chatlyne",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/chatlyne/chatlyne-app-icon-portfolio-display-image.png`,
        alternativeImageTitle:"Chatlyne",
        title:"Chatlyne"
    },
    "nextgearcrew": {
        linkTo:"/nextgearcrew",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/app_icon.png`,
        alternativeImageTitle:"Next Gear Crew",
        title:"Next Gear Crew"
    },
    "potburn": {
		linkTo:"/potburn",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/potburn/potburn-app-icon-portfolio-display-image.png`,
        alternativeImageTitle:"Potburn",
        title:"Potburn"
    },
    "mytimes": {
        linkTo:"/mytimes",
        imageURL:`${process.env.PUBLIC_URL}/assets/images/portfolio/mytimes/final/MYTimes-portfolio-display-image.png`,
        alternativeImageTitle:"MyTimes",
        title:"MyTimes"
    },
};