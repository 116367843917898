// These constants represent the static data and information that will
// be used to populate this component.

export const PortfolioProjectIdentifier = {
    brainstorm: "brainstorm",
    chatlyne: "chatlyne",
    cwprogresshud: "cwprogresshud",
    modularsidebarview: "modularsidebarview",
    mytimes: "mytimes",
    nextgearcrew: "nextgearcrew",
    potburn: "potburn",
    snapchatclone: "snapchatclone",
    celestial: "celestial",
    composabledatasource: "composabledatasource",
};

export const PortfolioStaticDetails = {
    "brainstorm": {
        title: "Brainstorm",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/brainstorm/w imac frame/brainstorm-profilescreen-image1_imac2015retina_front.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/brainstorm/w imac frame/brainstorm-projectscreen-image1_imac2015retina_front.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/brainstorm/w imac frame/brainstorm-inboxscreen-image1_imac2015retina_front.png`
        ],
        buttons: [
            {projectType: "web", url: "http://codearray-com.stackstaging.com/"}
        ],
        languages_used: [
            "HTML", "CSS", "Javascript", "jQuery", "PHP", "SQL"
        ],
        used_technologies: [
            {url: "https://developers.google.com/maps/documentation/javascript/", title: "Google Maps Javascript API"},
            {url: "https://developers.google.com/maps/documentation/geocoding/intro", title: "Google Maps Geocoding API"}
        ],
        descriptions: {
            description_text_one: `In order to complete my bachelor's of science degree at NYIT, I was required to complete a 
            Senior Design course in which I'd have to deliver a working project by the end of the course.`,
            description_text_two: `Brainstorm was inspired by a number of reasons. For starters, I
            had been frustrated by the lack of a community feel among engineering students at my school.
            I felt that although there must have been a number of above-average students with skills in 
            various fields, no one ever communicated to the point of collaborating on a project.`,
            description_text_three: `So amid the quickly approaching deadline to come up with an idea for the senior project,  
            my group mates and I quickly began thinking of good ideas to work with. I do not remember what was said,
            but for whatever reason, I responded with something along the lines of "no, we are just brainstorming".
            A second after saying this, I decided to name the application "Brainstorm".`
        }
    },


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 



    "chatlyne": {
        title: "Chatlyne",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/chatlyne/ios/with-shotbot-frame/timelyne-screen.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/chatlyne/ios/with-shotbot-frame/chatroom-screen.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/chatlyne/ios/with-shotbot-frame/camera-screen.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/chatlyne/ios/with-shotbot-frame/explore-screen.jpg`
        ],
        buttons: [
            {projectType: "ios", url: "https://itunes.apple.com/US/app/id1184927300?mt=8"}
        ],
        languages_used: [
            "Swift 3.0"
        ],
        used_technologies: [
            {url: "http://www.google.com/firebase/", title: "Firebase"},
            {url: "https://github.com/firebase/geofire-objc", title: "GeoFire"},
            {url: "https://onesignal.com/", title: "OneSignal"},
            {url: "https://get.fabric.io/", title: "Twitter Fabric (Now merged with Firebase"},
            {url: "https://get.digits.com/blog/digits-is-now-shut-down", title: "Twitter Digits (Now merged with Firebase"} 
        ],
        descriptions: {
            description_text_one: `Chatlyne was my third production-level iOS application. It is a messenger platform mixed the ability to share photos, videos or
            thoughts with friends. My first intentions when building Chatlyne
            was truly to push my skills with Swift and build something on par
            with Snapchat and Facebook.`,
            description_text_two: `Chatlyne was the first to be developed programmatically 
            from start to finish. I did not use any Storyboards or 
            XiBs at all throughout the application. I found this method 
            to be somewhat easier and has now become my preference in regards
            to iOS development. However, I still retain my expertise with
            Storyboards and Autolayout as a recent developer challenge required 
            its use. `,
            description_text_three: `I regard Chatlyne as my greatest personal achievement as it
            features my best work. Examples include my first thrust into
            building custom UI elements such as Popup views and 
            capturing images and videos with the camera.`
        }
    },


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


    "cwprogresshud": {
        title: "CWProgressHUD",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/cwprogresshud/cwprogresshud_showwithmessage_light.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/cwprogresshud/cwprogresshud_showsuccesswithmessage_dark.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/cwprogresshud/cwprogresshud_show_light.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/cwprogresshud/cwprogresshud_showprogress_dark.png`
        ],
        buttons: [
            {projectType: "open_source", url: "https://github.com/ChrishonWyllie/CWProgressHUD"}
        ],
        languages_used: [
            "Swift 4.0"
        ],
        used_technologies: [
            {url: "https://developer.apple.com/documentation/uikit", title: "UIKit"}
        ],
        descriptions: {
            description_text_one: `CWProgressHUD is a quick and clean progress hud popup for iOS.
            It is available as a cocoapod dependency.`,
            description_text_two: `Throughout the development process of my production-level
            applications, I often used third party cocoapod libraries
            to handle UI displays, transitions and animations. However,
            as I developed more applications, I bagan to regard using such
            libraries as an insult to my skill. I knew that I could build my own 
            libraries and phase out the third-party ones, so I began to do just that. 
            <a className="paragraph-link" href="/chatlyne"> Chatlyne </a>
             provided unique challenges from which I learned to build custom UI elements
            and therefore used throughout the app. From this, I decided to pull some of 
            that code and build my first open source cocoapod library: CWProgressHUD, 
            the first of many
            to come. `,
            description_text_three: `It is intended to be used when performing actions
            that require notifying the user. For example, displaying 
            a loading animation when the user is signing up or 
            displaying an error message when some time consuming task
            could not be completed, etc.`
        }
    },


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 



    "modularsidebarview": {
        title: "ModularSidebarView",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/modularsidebarview/final/ModularSidebarView_home_screen.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/modularsidebarview/final/ModularSidebarView_sidebar_screen.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/modularsidebarview/ModularSidebarView_demo_video.gif`
        ],
        buttons: [
            {projectType: "open_source", url: "https://github.com/ChrishonWyllie/ModularSidebarView"}
        ],
        languages_used: [
            "Swift 4.0"
        ],
        used_technologies: [
            {url: "https://developer.apple.com/documentation/uikit", title: "UIKit"}
        ],
        descriptions: {
            description_text_one: `ModularSidebarView is customizable menu for displaying options on the side of the screen. 
            It is available as a cocoapod dependency and on Github.`,
            description_text_two: `I decided to develop ModularSidebarView in order to provide a 
            more unique way to traversing the navigational stack. I wanted
            to contribute to the open source community with a simple, easy to use and customizable
            method.`,
            description_text_three: `It is intended to replace the traditional UINavigationController and 
            its navigationBar and toolbar. Users of this dependency may provide
            their own custom menu item designs by providing a UICollectionViewCell
            class in order to be registered
            They may also provide a variety of other functions and designs using the SidebarViewDelegate.`
        }
    },


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


    "mytimes": {
        title: "MyTimes",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/mytimes/final/MYTimes_homescreen_image2_iphonexspacegrey_portrait.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/mytimes/final/MYTimes_articledetail_image1_iphonexspacegrey_portrait.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/mytimes/final/MYTimes_articledetail_share_image1_iphonexspacegrey_portrait.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/mytimes/final/MYTimes_savedarticles_image1_iphonexspacegrey_portrait.png`
        ],
        buttons: [

        ],
        languages_used: [
            "Swift 4.0"
        ],
        used_technologies: [
            {url: "https://developer.apple.com/documentation/coredata", title: "Core Data"},
            {url: "https://developer.nytimes.com/", title: "New York Times Api"}
        ],
        descriptions: {
            description_text_one: `MYTimes was a developer challenge provided during the 
            course of an interview for an iOS engineer position. I built
            it within 4 days. I had been given free rein with the project
            as long as I followed a few design principles`,
            description_text_two: `The objective of the challenge was of course to test my skill
            and see if I would be a good fit for the role. Aside from showcasing
            my skillset during the interview process, I had previously never used 
            the New York Times API nor used Core Data in such detail to store and 
            retrieve custom class objects.`,
            description_text_three: `While this project is not publicly available like the others, it remains
            an important learning experience as I will definitely recycle some of the 
            components related to Core Data in future projects. Additionaly, from a 
            personal perspective, it gives me access to New York Times articles in a 
            format that I have personally built.`
        }
    },


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


    "nextgearcrew": {
        title: "NextGearCrew",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/ios/cropped-ios-device-screenshot-event-directions-screen_iphone8silver_portrait.png`,
			`${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/android/cropped-android-device-screenshot-searchscreen_pixel_very_silver_portrait.png`,
			`${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/ios/cropped-ios-device-screenshot-mychatrooms-screen_iphone8silver_portrait.png`,
			`${process.env.PUBLIC_URL}/assets/images/work/next-gear-crew/android/cropped-android-device-screenshot-myprofilescreen_pixel_very_silver_portrait.png`
        ],
        buttons: [
            {projectType: "ios", url: "https://itunes.apple.com/us/app/next-gear-crew/id1183996071?mt=8"},
            {projectType: "android", url: "https://play.google.com/store/apps/details?id=com.chrishonwyllie.nextgearcrew"}
        ],
        languages_used: [
            "Swift 3.0 (iOS)",
            "Java 8 (Android)"
        ],
        used_technologies: [
            {url: "http://www.google.com/firebase/", title: "Firebase"},
            {url: "https://github.com/firebase/geofire-objc", title: "GeoFire"},
            {url: "https://onesignal.com/", title: "OneSignal"},
            {url: "https://get.fabric.io/", title: "Twitter Fabric (Now merged with Firebase"},
            {url: "https://get.digits.com/blog/digits-is-now-shut-down", title: "Twitter Digits (Now merged with Firebase"},
            {url: "https://developers.facebook.com/docs/apis-and-sdks", title: "Facebook SDK"},
            {url: "https://dev.twitter.com/twitterkit/android/installation", title: "Twitter SDK"},
            {url: "https://developer.apple.com/documentation/coredata", title: "Core Data"},
            {url: "https://developers.google.com/maps/", title: "Google Maps"}
        ],
        descriptions: {
            description_text_one: `Next Gear Crew was my second iOS application, and subsequently, my
            second Android application built from scratch. However, it was built for client and
            not a personal project. It is intended to be used to make communication
            easier for members of the client's organization as well as providing 
            navigation to company-sponsored event, which are expected to happen on a daily
            basis.`,
            description_text_two: `Next Gear Crew was built and is currently maintained for the 
            New York-based car club of the same name. I was approached in mid
            2015 by one of their administrators who recognized that I had 
            experience in developing iOS applications. Initially at the time, my
            skill in iOS development was at a junior level, as I had never built
            a production-level application. However, as time went on and the feature
            requests created new challenges, my understanding of iOS development grew.`,
            description_text_three: `Although the admins and designers make the final decisions for the direction
            of Next Gear Crew, I have enjoyed a position of near complete autonomy in regard
            to the mobile applications and have been able to suggest and implement my own ideas.
            Big changes are underway for Next Gear Crew which will come to light in update 2.0.
            Until then, some of the lesser updates scheduled to come first.`
        }
    },



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


    "potburn": {
        title: "Potburn",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/potburn/ios/home_screen_image_iphonexspacegrey_portrait.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/potburn/android/playstore_screenshot2_pixel_very_silver_portrait.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/potburn/ios/discover_filter_screen_iphonexspacegrey_portrait.png`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/potburn/android/playstore_screenshot3_pixel_very_silver_portrait.png`
        ],
        buttons: [
            {projectType: "ios", url: "https://itunes.apple.com/us/app/potburn/id1148541637?mt=8"},
            {projectType: "android", url: "https://play.google.com/store/apps/details?id=com.chrishonwyllie.potburn"}
        ],
        languages_used: [
            "Swift 3.0 (iOS)",
            "Java 8 (Android)"
        ],
        used_technologies: [
            {url: "http://www.google.com/firebase/", title: "Firebase"},
            {url: "https://onesignal.com/", title: "OneSignal"},
            {url: "https://get.fabric.io/", title: "Twitter Fabric (Now merged with Firebase"},
            {url: "https://get.digits.com/blog/digits-is-now-shut-down", title: "Twitter Digits (Now merged with Firebase"},
        ],
        descriptions: {
            description_text_one: `Potburn was my first production-level iOS application.
            It was built for the purpose of providing a social
            platform for learning to cook. Initially, Potburn 
            proved to be my greatest challenge and ultimately led
            to the creation of my other projects.`,
            description_text_two: `I did a competitive analysis with some of the other
            cooking apps on the market while observing how some 
            young people go about learning to cook. I arrived at
            the conclusion that the people in my "test-pool" had
            either never used a cooking app or found the recipes to 
            be too "perfect" and therefore un-inviting to attempt.
            

            I then decided that there needed to be a more beginner-friendly
            atmosphere mixed with a social media-esque feel in order to 
            drive young people to learning how to cook. From this, the idea
            for Potburn sprang. In fact, the very name is a play on the fact
            that people that are new to cooking often burn pots in the process`,
            description_text_three: `While Potburn was my first iOS application, and first 
									large-scale project overall, I went on to develop an 
									Android version and currently in the process of building
									a web application to complete what I call "The Triangle".
									The iOS and Android versions`
        }
    },



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 



    "snapchatclone": {
        title: "Snapchat clone",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/projectzero/w_shotbot_frame/chatroom_options_screen.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/projectzero/w_shotbot_frame/memories_screen.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/projectzero/w_shotbot_frame/main_camera_screen.jpg`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/projectzero/w_shotbot_frame/profile_and_stories_screen.jpg`
        ],
        videos: [
            "https://www.youtube.com/embed/YXF4WGaOk0Y"
        ],
        progression: [
            {
                type: "Progression", 
                level: 84.3,
                text: `I'd like to think that this project is around 84.3% complete. I've implemented 
                        some of the bigger features such as the main camera, memories screen, messaging, stories, etc. 
                        But I haven't completely addressed all visual inconsistencies and bugs.
                        Take a look at some of the completed and nearly-completed milestones below
                        `
            }
        ],
        milestones: [
            {
                title: "Main Camera",
                demoVideos: [
                    {isLocalVideo: false, videoURL: `https://www.youtube.com/embed/nM0WMrB4ciM`}
                ],
                description: `The main camera is virtually similar to the original. Users can tap the "main" button 
                                to capture images and hold it to capture videos. Afterward, they can edit the media 
                                with text, emojis, drawings, etc.
                            `
            },
            {
                title: "Memories",
                demoVideos: [
                    {isLocalVideo: false, videoURL:`https://www.youtube.com/embed/9J3_RQAiS8E`}
                ],
                description: `Admittely, The Memories screen is not up to date with the current "look" of the original.
                                But most of features and behaviors work as intended.
                                Examples include viewing all the photos/videos in a device's camera roll,
                                any stories that were created from past snaps, and uploaded snaps themselves.
                                `
            },
            {
                title: "Messages",
                // demoVideos: [
                    // `https://www.youtube.com/embed/YXF4WGaOk0Y`,
                // ]
                description: ``
            },
            {
                title: "Stories",
                // demoVideos: [
                    // `https://www.youtube.com/embed/YXF4WGaOk0Y`,
                // ]
                description: ``
            },
            {
                title: "Snap Map",
                // demoVideos: [
                    // `https://www.youtube.com/embed/YXF4WGaOk0Y`,
                // ]
                description: ``
            }
        ],
        buttons: [
            {projectType: "private", url: ""}
        ],
        languages_used: [
            "Swift 5.0"
        ],
        used_technologies: [
            {url: "http://www.google.com/firebase/", title: "Firebase"},
            {url: "https://github.com/ChrishonWyllie/CWProgressHUD", title: "CWProgressHUD"},
        ],
        descriptions: {
            description_text_one: `This project began as an offshoot from my Chatlyne app. I wanted to perfect the
                                    camera in that app, and in doing so, I took notes from the real Snapchat app.
                                    The more bugs I fixed in my version, the more it began to resemble the real thing.
                                    Eventually, I decided I want to implement some of the gestures such as swiping up
                                    to view images and videos from the camera roll.
                                    At this point, I realized that this would leave the scope of the Chatlyne app.
                                    `,
            description_text_two: `I grew so interested in recreating more features from the real Snapchat app, that
                                    I eventually put development on Chatlyne on hold to fully focus on this new
                                    Snapchat clone. 
                                    I started to really observe how Snapchat worked and discover ways that I could 
                                    emulate its functionality down to the most minute details.
                                    `,
            description_text_three: `My biggest issues were with frameworks that I was unfamiliar with and how I
                                    could complete this app by myself. But if I could narrow it down, the feature that
                                    gave me the most difficulty was the camera. Since the real Snapchat app is built and 
                                    maintained by a team of developers, I found myself constantly racing against the
                                    clock to maintain feature parity and often losing that battle with each subsequent
                                    release of the real Snapchat app. At this time, I have falled behind quite a bit while
                                    I try to figure out how to solve some issues. But it remains an ongoing struggle that
                                    I truly enjoy.
                                    `
        }
    },


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 



    "celestial": {
        title: "Celestial",
        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/celestial-app-demo-without-caching.gif`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/celestial-app-demo-with-caching.gif`
        ],
        progression: [
            {
                type: "Progression", 
                level: 89.0,
                text: `The Celestial open source cocoapod is virtually complete, in that most of the important
                        infrastructure is in place and essential functions are working as intended.
                        However, I have a few stretch goals in mind that would further optimize the
                        caching feature as well as provide support for other scenarios.
                        `
            }
        ],
        milestones: [
            {
                title: "Image Caching",
                demoVideos: [
                    {isLocalVideo: true, footerTitle: "Without Caching", videoURL: `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/original-demo-videos/celestial-app-demo-without-caching.mov`},
                    {isLocalVideo: true, videoURL: `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/original-demo-videos/celestial-xcode-memory-demo-without-caching.mov`},
                    {isLocalVideo: true, footerTitle: "With Caching", videoURL: `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/original-demo-videos/celestial-app-demo-with-caching.mov`},
                    {isLocalVideo: true, videoURL: `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/original-demo-videos/celestial-xcode-memory-demo-with-caching.mov`}
                ],
                description: `Image caching is achieved by storing a downloaded image with a key 
                              (usually the URL of the file on an external server)
                              Instead of downloading the image again, Celestial will supply the 
                              previously downloaded image immediately, resulting in fewer API calls. 
                              However, these details can be skipped by using the provided URLImageView
                              class which is a subclass of the UIKit view: UIImageView.
                              It provides a function for loading images using a url string.
                             `
            },
            {
                title: "Video Caching",
                // demoVideos: [
                    // `https://www.youtube.com/embed/YXF4WGaOk0Y`,
                // ]
                description: `Video caching is similar to image caching, however there is an intermediary
                              struct called "OriginalVideoData". This is necessary for playing the video after
                              the file is cached and requested.
                              The first time a video file is downloaded from an external server, the file
                              is cached using the original url. Each time this file is requested thereafter, 
                              the cached version will be provided to prevent making redundant API calls.
                              However, these details can be skipped by using the provided CachableAVPlayerItem
                              class which is a subclass of the AVFoundation object: AVPlayerItem which may already be
                              familiar to developers.
                             `
            }
        ],
        buttons: [
            {projectType: "open_source", url: "https://github.com/ChrishonWyllie/Celestial"}
        ],
        languages_used: [
            "Swift 5.0"
        ],
        used_technologies: [
            {url: "https://developer.apple.com/documentation/uikit", title: "UIKit"}
        ],
        descriptions: {
            description_text_one: `Celestial is a video and image cache manager for iOS.
            It is available as a cocoapod dependency.`,
            description_text_two: `Displaying UIImages in UICollectionViews and UITableViews can be
            tricky sometimes. Especially when the images will be asynchronously downloaded from an external
            server. The time it takes for the image to finish downloading can affect the user's experience
            by causing the scrolling to 'lag' and in some cases be completely unresponsive for a second or two.
            And then there's the mess with threads... TL:DR, it gets messy. 
            For videos, it can be another story entirely. Displaying videos in a scroll view has its own drawbacks.
            So this is where Celestial comes in. It provides a set of utility functions and views to streamline these
            often annoying processes.
             `,
            description_text_three: `It is intended to be used when downloading and displaying images and videos
            a single time from an external server. For example, displaying 
            images in a UICollectionView can slow down scrolling if the image is repeatedly
            downloaded each time the cell is dequeued. Celestial will store the images or videos
            locally and provide them as necessary to the view. This prevents making redundant requests to 
            the server, decreases image-display time and reduces the occurence of an incorrect image or video being loaded
            to an incorrect UICollectionViewCell or UITableViewCell.
            .`
        }
    },

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 

    "composabledatasource": {
        title: "ComposableDataSource",
        progression: [
            {
                type: "Progression", 
                level: 95.0,
                text: `
                ComposableDataSource encapsulates UICollectionView DataSource and Delegate functions elsewhere,
                and moving redundant or often, tedious code out of the ViewController
                `
            }
        ],

        images: [
            `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/celestial-app-demo-without-caching.gif`,
            `${process.env.PUBLIC_URL}/assets/images/portfolio/celestial/celestial-app-demo-with-caching.gif`
        ],
    },
}; 