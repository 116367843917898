import React from 'react';
import '../css/index.css';
import '../css/animatablecard.css';

export default class AnimatableCard extends React.Component {

    getImageURL = () => {
        if(this.props.imageURL) {
            return this.props.imageURL
        } else {
            // return a default image
            return `${process.env.PUBLIC_URL}/assets/images/backgrounds/header-background-one-1024.jpg`
        }
    }

    render() {
        return (
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 animatable-card-column">
                <div className="animatable-card-row-item">
                    
                    <a className="animatable-card-link-container" href={this.props.linkTo}>
                        <div className="animatable-card-image-container">	
							<img className="img-responsive animatable-card-thumbnail-image" src={this.getImageURL()} alt={this.props.alternativeImageTitle} />					   
                            <div className="animatable-card-image-overlay">
                                <button className="animatable-card-image-button bttn-inverted bttn-rounded bttn-inverted-bordered">View</button>
                            </div>
                        </div>
                    </a>
                    
                    <div className="animatable-card-text-container">
                        <h4 className="animatable-card-item-text text-center">
                            {this.props.title}
                        </h4>
                    </div>
                    
                </div>
            </div>
        );
    }
}